<template>
  <div id="deposit-funds">
    <div class="row">
      <h1>Deposit Funds</h1>
    </div>

      <div class="row">

      <div class="col-lg-12 col-sm-12">
        <div class="card card-chart" v-for="item in depositInstructions" v-bind:key="item.id">
            <div class="card-body">
              <p><span v-html="item.instruction_details"></span></p>
              <p>&nbsp;</p>
            </div>
        </div>
      </div><!-- End Column    -->

      <div class="col-md-12 col-sm-12 col-xs-12" v-if="!isModel" style="margin-top:30px;">
          <h3>Click below to Request for Deposit</h3>
          <div align='center'><button @click.prevent="requestDeposit" class="alert alert-primary btn-block"><strong>Request Deposit</strong></button> </div>
      </div>

      <!-- Start Submit Buy or Sell Options -->
      <div class="col-md-12 col-sm-12 col-xs-12" align="center" v-if="isModel">
        <div style="border:1px solid #000; border-radius: 25px; width:80% !important; margin-top:20px; margin-bottom:20px;">
          <h3 style="margin-top:20px;"><b>Request Deposit</b></h3>

            <div class="form-group">
              <label for="select-input">Select Trading Package</label>
              <select class="form-control" id="select-input" v-model="trading_package" required>
                <option selected align="center" :value="''">Select Trading Package</option>
                <option v-for="option in tradingPackage" :key="option" :value="option">{{ option }}</option>
              </select>
            </div>

            <div class="form-group">
              <label for="select-input">Select Trading Type</label>
              <select class="form-control" id="select-input" v-model="trading_type" required>
                <option selected align="center" :value="''">Select Trading Type</option>
                <option v-for="option in tradingType" :key="option" :value="option">{{ option }}</option>
              </select>
            </div>

            <div class="form-group">
              <label for="select-input">Select Deposit Type</label>
              <select class="form-control" id="select-input" v-model="deposit_type" required>
                <option selected align="center" :value="''">Select Deposit Type</option>
                <option v-for="option in depositType" :key="option" :value="option">{{ option }}</option>
              </select>
            </div>

             <div class="form-group">
              <label for="select-input">Select Trading Duration</label>
              <select class="form-control" id="select-input" v-model="trading_duration" required>
                <option selected align="center" :value="''">Select Trading Duration</option>
                <option v-for="option in TradingDuration" :key="option" :value="option">{{ option }} Days</option>
              </select>
            </div>

            <div class="form-group">
              <label for="select-input">Enter Amount ($) numbers only</label>
              <input type="text" v-model="amount" placeholder="Enter Amount to Deposit" class="form-control" @input="onInput" required />
            </div>

            <div class="form-group">
              <button @click="submitTrade()" :disabled="disableButton" class="alert alert-success">Confirm</button>
              <button @click="cancelTrade()" class="alert alert-danger">Cancel</button>
              <p v-if="loading">Please wait...</p>
            </div>
        </div>
      </div>
      <!-- End Submit Buy or Sell Options -->
      
      <div class="col-lg-6 col-md-6 col-sm-6" v-for="bank in bankDeails" v-bind:key="bank.id">
        <div class="card card-chart">
          <p align="center" style="margin-bottom: -10px !important; color: red;"><b>Click Below for Deposit Details</b></p>
          <div class="" style="margin-top:15px !important; padding:5px !important; margin-bottom:15px !important;">
            <h5 class="page-title" align="center" @click="selectItem(bank.id)"><a>{{ bank.title }}</a></h5>
          </div>
          <div class="card-body" style="display: none !important;" v-show="isOpen" v-if="bank.id === activeItem">
            <p><span v-html="bank.bank_details"></span></p>
            <p v-if="bank.file"><img :src="bank.file" width="100%"></p>
          </div>
        </div>
      </div>
      <!-- End Column    -->

  </div>
  <div class="row">
     <div class="col-lg-12 col-md-12">       
        <div class="card">
          <div class="" style="margin-top:15px !important;">
            
            <h4 class="page-title">Deposit Transcations</h4>
            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
          </div>
          
          <div class="card-body table-responsive">
            <br>
            <table class="table table-hover">
              <thead class="text-primary">
                <th>Transaction ID</th>
                <th>Trading Type</th>
                <th>Trade Package</th>
                <th>Amount paid</th>
                <th>Profit</th>
                <th>Trading Status</th>
                 <th>Date</th>
              </thead>
              <tbody>
                <tr v-for="deposit in depositTranscations" v-bind:key="deposit.id">
                  <td>{{ deposit.transaction_id }}</td>
                  <td>{{ deposit.trading_type }}</td>
                  <td>{{ deposit.trading_package }}</td>
                  <td>$ {{ deposit.amount }}</td>
                  <td>$ {{ deposit.profit }}</td>
                  <td>{{ deposit.trading_status }}</td>
                  <td>{{ deposit.start_date | formatdate }}</td>
                </tr>                
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- End Column -->
  </div>
  <!-- <BackendScripts /> -->
  </div>
</template>

<script>
import axios from "axios";
import API_URL  from './../common/config'
import Vue from "vue";
import moment from "moment";

Vue.filter("formatdate", function(value) {
 if (value) {
 return moment(String(value)).format("MM-DD-YYYY");//"MM/DD/YYYY hh:mm"
 }
});

export default {
    name: 'DepositFunds',
    components: {
      // BackendScripts
    },
    data() {
      return {
        activeItem: null,
        isOpen: false,
        depositInstructions: [],
        bankDeails: [],
        depositTranscations: [],
        selectedOption: '',
        selectedText: '',
        isModel: false,
        TradingDuration: [7, 14, 21, 30],
        tradingType: ['Cryptocurrency', 'Forex', 'Binary'],
        tradingPackage: ['Personal', 'Pro V6', 'Standard', 'Premium'],
        depositType: ['Binary/Forex', 'Bitcoin', 'Ethereum'],
        trading_duration: '',
        trading_type: '',
        trading_package: '',
        deposit_type: '',
        amount: null,
        disableButton: false,
        loading: false
      }
    },
    created() {
      this.getDepositInstruction()
      this.getBankDetails()
      this.getDepositFunds()
    },
    methods: {
      requestDeposit(){
        this.isModel = true
      },
      onInput() {
        let sanitizedInput = this.amount.replace(/[^0-9]/g, "");
        this.amount = sanitizedInput;
      },
      submitTrade() {
        this.disableButton = true
        this.loading = true
        console.log('== selectedOption', this.trading_type, this.trading_package, this.deposit_type, this.trading_duration, this.amount );
        if(this.trading_type && this.trading_package && this.deposit_type && this.trading_duration && this.amount ){

        const postData = {trading_type:this.trading_type, trading_package:this.trading_package, deposit_type:this.deposit_type, trading_duration:this.trading_duration, amount:this.amount }

        var token = JSON.parse(localStorage.getItem('userData')).token
        var headers = {'Authorization': `Token ${token}`}

        axios.post(API_URL + 'deposit-withdraw/submit-deposit/', postData, {headers:headers})
        .then(() => {
          alert('send!')
          this.disableButton = false
          this.isModel = false
          this.loading = false
        })
        } else {
          this.disableButton = false
          alert('Please the Required Fields!')
          this.loading = false
        }
      /*username
        trading_type
        trading_package
        deposit_type
        trading_duration
        amount */
      },
      cancelTrade(){
        this.isModel = false
      },
      getDepositInstruction() {
        axios.get(API_URL + 'deposit-withdraw/deposit-instructions/')
        .then(res => {
          this.depositInstructions = res.data
        })
      },
      getBankDetails() {
        axios.get(API_URL + 'deposit-withdraw/bank-details/')
        .then(res => {
          //console.log('this.bankDeails = ', res.data)
          this.bankDeails = res.data
        })
      },
      selectItem(i) {
        this.activeItem = i;
        this.isOpen = !this.isOpen
        console.log('this.activeItem = ', this.activeItem)
        console.log('this.isOpen = ', this.isOpen)
      },
      getDepositFunds() {
      this.$store.dispatch('loadDepost').then(
        data => {          
          this.depositTranscations = data
          //console.log('depositTranscations =', data)
        })
      }
    },
}
</script>

<style scoped>
.activeItem {
  display: block;
}
p {
  font-size: 14px;
}
</style>