
<template>
  <div id="about-us" class="about-us">
        <!--page title start-->
        <section id="about-us" class="page-title page-title-center cover-1 padding-top-220 padding-bottom-120 overlay purple-5 fixed-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="white-text font-40 text-bold">About Us</h2>
                        <ol class="breadcrumb">
                            <li><a href="#">Home</a></li>
                            <li class="active">About Us</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-padding">
          <div class="container">
            <div itemprop="articleBody">
                <p><span style="color: #0066B3;"><b><span style="font-size: 20pt; line-height: 115%;">VesTrade’s Mission Is To Bring Trading To The Masses.</span></b></span></p>
                <p><span style="font-size: 14pt; line-height: 115%;">We do so by providing an intuitive trading platform designed with the latest technological advancements that’s geared towards new traders as well as professionals. We place great emphasis on education, providing a well-rounded library of V.O.D. seminars, webinars, daily news, analysis and more. Our customer service and support is always available to meet your needs, and our account managers know that your success is our success.</span></p>
                <p>&nbsp;</p><br>
                <p><span style="color: #0066B3;"><b><span style="font-size: 22pt; line-height: 115%;">Our Vision</span></b></span></p>
                <p><span style="font-size: 14pt; line-height: 115%;">VesTrade was established as a way for traders to invest in the financial markets on a platform that is user friendly.</span></p>
                <p><span style="font-size: 14pt; line-height: 115%;">Your success is a testament of our commitment to any investor who chooses to trade with VesTrade. That is why we strive to provide you with an expanding asset selection, dedicated support and personal assistance.</span></p>
                <p>&nbsp;</p><br>
                <p><span style="color: #0066B3;"><b><span style="font-size: 22pt; line-height: 115%;">Our Commitment</span></b></span></p>
                <p><span style="font-size: 14pt; line-height: 115%;">Integrity Matters</span></p>
                <p><span style="font-size: 12pt; line-height: 115%;"></span></p>
                <p>&nbsp;</p><br>
                <p><span style="color: #0066B3;"><b><span style="font-size: 22pt; line-height: 115%;">Connecting Opportunities</span></b></span></p>
                <p><span style="font-size: 14pt; line-height: 115%;">Opportunities arise daily and are numerous, and we provide you with the means to connect from one to the other</span></p>
                <p>&nbsp;</p><br>
                <p><span style="font-size: 12pt; line-height: 115%;"></span></p>
                <p><span style="color: #0066B3;"><b><span style="font-size: 22pt; line-height: 115%;">Maintain Control</span></b></span></p>
                <p><span style="font-size: 14pt; line-height: 115%;">Maintaining control is the ability to drive your success however – and whichever – way you want.</span></p>
                <p>&nbsp;</p><br>
                <p><span style="font-size: 12pt; line-height: 115%;"></span></p>
                <p><span style="color: #0066B3;"><b><span style="font-size: 22pt; line-height: 115%;">Personal, Professional Support</span></b></span></p>
                <p><span style="font-size: 14pt; line-height: 115%;">When market volatility equals trading opportunities, count on steady, dedicated support to assist you every step of the way</span></p>
                <p>&nbsp;</p><br>
                <p><span style="font-size: 12pt; line-height: 115%;"></span></p>
                <p><span style="color: #0066B3;"><b><span style="font-size: 22pt; line-height: 115%;">Freedom of Choice</span></b></span></p>
                <p><span style="font-size: 14pt; line-height: 115%;">Benefit from tailored solutions that meet your needs. Over 100 tradable assets and unlimited market access</span></p>
                <p>&nbsp;</p><br>
                <p><span style="color: #0066B3;"><b><span style="font-size: 22pt; line-height: 115%;">Customer Support</span></b></span></p>
                <p><span style="font-size: 14pt; line-height: 115%;">Whether you’re new to the markets or a professional, dedicated customer support is an integral part of a successful trading experience; if you have questions, comments or concerns, our local staff is on-hand to answer all your needs.</span></p>
                <p><span style="font-size: 14pt; line-height: 115%;">Contact us&nbsp;by&nbsp;email or phone.</span></p>
                <p><span style="font-size: 14pt; line-height: 115%;">VesTrade is always interested in meeting talented applicants. If you’re interested in joining our teams.</span></p>
            </div>
          </div>
        </section>
        <!--page title end-->
        <!--  <section class="page-section">        
        <div v-for="event in data" :event="event" :key="event.id" >
        <div class="home-container" :key="event.id" v-if="event.content_url == 'about-us'">
          <p v-if="event.content_top_image"><img :src="event.content_top_image"></p>
          <h3>{{ event.content_title }}</h3>
          <div v-html="event.content_text" class="content_text"></div>
          <p v-if="event.content_bottom_image"><img :src="event.content_bottom_image"></p>
          <br><hr><br>
        </div>
        </div>
        </section> -->
  </div>
</template>

<script>
// import axios from "axios";
// import { API_URL }  from '../common/config'

//const source = axios.CancelToken.source();

export default {
/* eslint-disable */
  name: 'AboutUs',
  data () {
    return {
      // data: []        
    }
    },
    created(){
        
    },
    mounted() {
/*     axios
      .get(API_URL + '/pages/lists/')
      .then(
          response => {             
              this.data = response.data;
              //console.log('==response.data= ', this.data)              
    }) */
  }
}
</script>

<style>

</style>