/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';
import Home from './../components/Home.vue';
import AboutUs from './../components/AboutUs.vue';
import ContactUs from './../components/ContactUs.vue';
import Deposit from './../components/Deposit.vue';
import ProV6 from './../components/pro-v6.vue';

import CryptoTrading from './../components/Cryptocurrency.vue';
import ForexTrading from './../components/Forex.vue';
import BinaryTrading from './../components/BinaryTrade.vue';
import GuidePage from './../components/Guide.vue';
import RegulationPage from './../components/Regulation.vue';

import Login from  './../components/auth/login.vue';
import ReferalSignup from './../components/auth/referal-signup.vue';
import AdminLogin from  './../components/auth/admin-login.vue';
import Frontend from './../layouts/frontend';
import Backend from './../layouts/backend';
import HomeLayout from './../layouts/home';
import AdminDashboard from './../administrator/dashboard';
import UserDashboard from './../users/dashboard';
import UserBackend from './../layouts/userbackend';
import UserCryptoTrading from './../users/crypto-trading';
import UserBinaryTrading from './../users/binary-trading';

import AdminChangePassword from './../administrator/change-password';
import AdminDepositTransaction from './../administrator/deposit-funds';
import AdminCryptoTrading from './../administrator/crypto-trading';
import AdminForexTrading from './../administrator/forex-trading';
import AdminSignupUsers from './../administrator/signup-users';
import AdminReferralsProgram from './../administrator/referrals-program';
import AminTradingReports from './../administrator/trading-reports';
import AdminWithdrawFunds from './../administrator/withdraw-funds';

import UserChangePassword from './../users/change-password';
import UserDepositFunds from './../users/deposit-funds';
import UserEditMyProfile from './../users/edit-my-profile';
import UserForexTrading from './../users/forex-trading';
import UserPersonalProfile from './../users/personal-profile';
import UserReferFriend from './../users/refer-a-friend';
import UserUploadDocuments from './../users/upload-documents';
import UserWithdrawFunds from './../users/withdraw-funds';
import UserWalletBalance from './../users/wallet-balance';
import AuthGuard from './auth-guard'
//user_wallet_balance
Vue.use(Router)

const router = new Router({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: "/",
        component: Frontend,
        children: [
      {
        path: '',
        name: 'home',
        component: Home,
        meta: { scrollToTop: true }
      },
      {
        path: 'about-us',
        name: 'about_us',
        component: AboutUs,
        meta: { scrollToTop: true }
      },
      {
        path: 'crypto-trading',
        name: 'crypto_trading',
        component: CryptoTrading,
        meta: { scrollToTop: true }
      },  
      {
        path: 'forex-trading',
        name: 'forex_trading',
        component: ForexTrading,
        meta: { scrollToTop: true }
      },
      {
        path: 'binary-trading',
        name: 'binary_trading',
        component: BinaryTrading,
        meta: { scrollToTop: true }
      },
      {
        path: 'guide-page',
        name: 'guide_page',
        component: GuidePage,
        meta: { scrollToTop: true }
      },
      {
        path: 'deposit',
        name: 'deposit',
        component: Deposit,
        meta: { scrollToTop: true }
      },
      {
        path: 'pro-v6',
        name: 'pro_v6',
        component: ProV6,
        meta: { scrollToTop: true }
      },
      {
        path: 'regulation-page',
        name: 'regulation_page',
        component: RegulationPage,
        meta: { scrollToTop: true }
      },
     {
        path: 'contact-us',
        name: 'contact_us',
        component: ContactUs,
        meta: { scrollToTop: true },
        props: true
      },
      {
        path: 'login',
        name: 'login',
        component: Login,
        alias: '/login',
        meta: { scrollToTop: true }
      },
      {
        path: 'referal-signup/:code',
        name: 'referal_signup',
        component: ReferalSignup,
        props: true,
        meta: { scrollToTop: true }
      },
      {
        path: 'admin-login',
        name: 'admin_login',
        component: AdminLogin,
        meta: { scrollToTop: true }
      }
    ]
    },
    // import Backend from './../layouts/backend';
    {
      path: '/admin',
      component: Backend,
      beforeEnter: AuthGuard,
      children: [
    {
      path: '',
      name: 'admin_dashboard',
      component: AdminDashboard,
      alias: '/admin/admin_dashboard',
      meta: { scrollToTop: true }
    },
    {
      path: 'admin-crypto-trading',
      name: 'admin_crypto_trading',
      component: AdminCryptoTrading,
      alias: '/admin/admin-crypto-trading',
      meta: { scrollToTop: true }
      },
      {
        path: 'admin-change-password',
        name: 'admin_change_password',
        component: AdminChangePassword,
        alias: '/admin/admin-change-password',
        meta: { scrollToTop: true }
      },
      {
        path: 'admin-deposit-transaction',
        name: 'admin_deposit_transaction',
        component: AdminDepositTransaction,
        alias: '/admin/admin-deposit-transaction',
        meta: { scrollToTop: true }
      },
      {
        path: 'admin-forex-trading',
        name: 'admin_forex_trading',
        component: AdminForexTrading,
        alias: '/admin/admin-forex-trading',
        meta: { scrollToTop: true }
      },
      {
        path: 'admin-signup-users',
        name: 'admin_signup_users',
        component: AdminSignupUsers,
        alias: '/admin/admin-signup-users',
        meta: { scrollToTop: true }
      },
      {
        path: 'admin-referrals-program',
        name: 'admin_referrals_program',
        component: AdminReferralsProgram,
        alias: '/admin/admin-referrals-program',
        meta: { scrollToTop: true }
      },
      {
        path: 'admin-trading-reports',
        name: 'admin_trading_reports',
        component: AminTradingReports,
        alias: '/admin/admin-trading-reports',
        meta: { scrollToTop: true }
      },
      {
        path: 'admin-withdrawals',
        name: 'admin_withdrawals',
        component: AdminWithdrawFunds,
        alias: '/admin/admin-withdrawals',
        meta: { scrollToTop: true }
      }
  ]
  }
  ,
  {
    path: '/user',
    component: UserBackend,
    name: 'user-backend',
    beforeEnter: AuthGuard,
    children: [
      {
        path: '',
        name: 'user_dashboard',
        component: UserDashboard,
        meta: { scrollToTop: true }
        },
        {
        path: 'user-crypto-trading',
        name: 'user_crypto_trading',
        component: UserCryptoTrading,
        alias: 'user/user-crypto-trading',
        meta: { scrollToTop: true }
        },
        {
          path: 'user-binary-trading',
          name: 'user_binary_trading',
          component: UserBinaryTrading,
          alias: 'user/user-binary-trading',
          meta: { scrollToTop: true }
          },
        {
          path: 'user-change-password',
          name: 'user_change_password',
          component: UserChangePassword,
          meta: { scrollToTop: true }
        },
        {
          path: 'user-deposit-funds',
          name: 'user_deposit_funds',
          component: UserDepositFunds,
          meta: { scrollToTop: true }
        },
        {
          path: 'user-edit-my-profile',
          name: 'user_edit_my_profile',
          component: UserEditMyProfile,
          meta: { scrollToTop: true }
        },
        {
          path: 'user-forex-trading',
          name: 'user_forex_trading',
          component: UserForexTrading,
          meta: { scrollToTop: true }
        },
        {
          path: 'user-personal-profile',
          name: 'user_personal_profile',
          component: UserPersonalProfile,
          meta: { scrollToTop: true }
        },
        {
          path: 'user-refer-a-friend',
          name: 'user_refer_friend',
          component: UserReferFriend,
          meta: { scrollToTop: true }
        },
        {
          path: 'user-upload-documents',
          name: 'user_upload_documents',
          component: UserUploadDocuments,
          meta: { scrollToTop: true }
        },
        {
          path: 'user-withdraw-funds',
          name: 'user_withdraw_funds',
          component: UserWithdrawFunds,
          meta: { scrollToTop: true }
        },
        {
          path: 'user-wallet-balance',
          name: 'user_wallet_balance',
          component: UserWalletBalance,
          meta: { scrollToTop: true }
        }
    ]
  }
    ]
  })//

// add navigation guard to scroll to top when a new route is accessed
// router.beforeEach((to, from, next) => {
//   if (to.meta.scrollToTop) {
//     window.scrollTo(0, 0);
//   }
//   next();
// });

export default router;