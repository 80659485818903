<template>
  <div id="admin-trading-reports">
    <div class="row">
      <h1>Trading Reports</h1>
  </div>
  <!-- <BackendScripts /> -->
  </div>
</template>

<script>
// import BackendScripts from '../components/Partials/backend-scripts'

export default {
    name: 'AminTradingReports',
    components: {
      // BackendScripts
    }
}
</script>

<style>

</style>