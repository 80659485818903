<template>
  <div id="withdraw-funds">
  <div class="row">
      <h1>Withdraw Funds</h1>
  </div>
<div class="row">

      <div class="col-lg-12 col-sm-12">
        <div class="card card-chart">
          <div class="" style="margin-top:15px !important;">
            <h4 class="page-title">Request for Withdrawal</h4>
          </div>
            <div class="card-body">
              <h3>Enter Request Amount ( $ )</h3>
                <form @submit.prevent="submitWithdrawal" style="magin-top:30px !important ;">
                <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="bmd-label-floating">e.g 1000, 250.00, 5000.50, etc</label>
                          <input id="requestAmount" name="requestAmount" v-model="requestAmount" required="required" type="float" class="form-control">
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <div class="form-group">
                            <label class=""> Bank Details </label>
                            <textarea class="form-control"  name="bankDetails" v-model="bankDetails" rows="5"></textarea>
                          </div>
                        </div>
                      </div>
                  </div>
                   <button type="submit" class="btn btn-success" align="center">Submit Request</button>
                    <div class="clearfix"></div>
                </form>
            </div>
        </div>
      </div><!-- End Column    -->


  </div>
  <div class="row">
     <div class="col-lg-12 col-md-12">       
        <div class="card">
          <div class="" style="margin-top:15px !important;">
            <h4 class="page-title">Withdrawal Transcations</h4>
            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
          </div>
          
          <div class="card-body table-responsive">
            <br>
            <table class="table table-hover">
              <thead class="text-primary">
                <th>Withdrawal ID</th>
                <th>Amount</th>
                <th>Status</th>
                 <th>Date</th>
              </thead>
              <tbody>
                <tr v-for="item in withdrawalDetail" v-bind:key="item.id">
                  <td>{{ item.request_id }}</td>
                  <td>$ {{ item.amount }}</td>
                  <td>{{ item.request_status }}</td>
                  <td>{{ item.requested_date | formatdate }}</td>
                </tr>                
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- End Column -->
  </div>
  <!-- <BackendScripts /> -->
  </div>
</template>

<script>
import axios from "axios";
import API_URL  from './../common/config'
import Vue from "vue";
import moment from "moment";

Vue.filter("formatdate", function(value) {
 if (value) {
 return moment(String(value)).format("MM-DD-YYYY");//"MM/DD/YYYY hh:mm"
 }
});

export default {
    name: 'WithdrawFunds',
    components: {
      // BackendScripts
    },
    data() {
      return {
        requestAmount: '',
        bankDetails: '',
        withdrawalDetail: [],
        success: false,
        error: false
      }
    },
    created() {
        // this is to initialized the status
        this.success = false
        this.error = false
        this.withdrawalDetails()
    },
    computed: {
      // this is to initialized watch: watchSuccess
      watchSuccess(){
         return this.$store.getters.success
      }
    },
    watch: {
      // this is to watch changes in the status
      watchSuccess(){
        this.$store.getters.error
        this.$store.getters.success
        this.withdrawalDetails()
      }
    },
    methods: {
      submitWithdrawal() {
          console.log({requestAmount: this.requestAmount, bankDetails: this.bankDetails})
          this.$store.dispatch('loadWithdrawal', {requestAmount: this.requestAmount, bankDetails: this.bankDetails})
          this.requestAmount = ''
          this.bankDetails = ''
      },
      withdrawalDetails() {
        var token = JSON.parse(localStorage.getItem('userData')).token
        var headers = {'Authorization': `Token ${token}`}

        axios.get(API_URL + 'deposit-withdraw/withdrawal-details/', {headers: headers})
        .then(res => {
          this.withdrawalDetail = res.data
        })
      }
    }

}
</script>

<style scoped>
button {
  font-size: 18px !important;
  padding: 10px 35px !important;
  width: 250px;
  justify-items: center;
}
label {
  font-size: 14px !important;
}
</style>