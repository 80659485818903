<template>
  <div id="wallet-balance">
    <div class="row">
      <h1>Wallet Balance</h1>
    </div>
  <div class="row">
     <div class="col-lg-12 col-md-12">       
        <div class="card">
          <div class="" style="margin-top:15px !important;">
            <h4 class="page-title">Wallet Balance</h4>
            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
          </div>
          
          <div class="card-body table-responsive">
            <br>
            <table class="table table-hover">
              <thead class="text-primary">
                <th>Wallet Balance</th>
                <th>Withdrawed Amount</th>
                <th>Amount Deposited</th>
                <th>Profit</th>
                <th>Trading Type</th>
                <!-- <th>Trade Package</th> -->
              </thead>
              <tbody>
                <tr v-for="deposit in walletBalance" v-bind:key="deposit.id">
                  <td>$ {{ deposit.balance }}</td>
                  <td>$ {{ deposit.withdraw }}</td>
                  <td>$ {{ deposit.amount }}</td>
                  <td>$ {{ deposit.profit }}</td>
                  <td>{{ deposit.trading_type }}</td>
                  <!-- <td>{{ deposit.trading_package }}</td> -->
                </tr>                
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- End Column -->
  </div>
  <!-- <BackendScripts /> -->
  </div>
</template>

<script>
// import axios from "axios";
// import API_URL  from '../common/config'
import Vue from "vue";
import moment from "moment";

Vue.filter("formatdate", function(value) {
 if (value) {
 return moment(String(value)).format("MM-DD-YYYY");//"MM/DD/YYYY hh:mm"
 }
});

export default {
    name: 'WalletBalance',
    components: {
      // BackendScripts
    },
    data() {
      return {
        walletBalance: [],
      }
    },
    created() {
      this.WalletBalance()
    },
    methods: {
      WalletBalance() {
      this.$store.dispatch('loadwalletBalance').then(
        data => {          
          this.walletBalance = data
          console.log('walletBalance =', data)
        })
      }
    },
}
</script>

<style scoped>
.activeItem {
  display: block;
}
p {
  font-size: 14px;
}
</style>