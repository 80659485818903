<template>
  <div id="upload-documents">
    <div class="row">
      <h1>Upload Documents</h1>
  </div>
  <!-- enctype="multipart/form-data"  -->
  <!-- @submit.prevent="submitFile" -->
  <form  @submit.prevent="submitFile">
  <div class="row">
    <div class="col-xs-12">
      <div>
        <div>
          <div>
            <br>
            <p><span>* Your account is <strong>not verified</strong></span></p>
          </div>
          <div class="card">
          <div class="card-body table-responsive">
            <p><span>As a regulated company, Vestrade is required to carry out appropriate   measures in order to verify its customers and to ensure the safety of   its customers in a secured trading environment.</span></p>
          </div>
           </div>

          <div class="card">          
          <div class="" style="margin-top:15px !important;">
            <h3 class="page-title" align="center">In order to <strong>verify your identity</strong> please provide the following documents:</h3>
          </div>
          <div class="card-body table-responsive">
              <div>
                <div>
                  <br><br>
                 <h3><span >1: </span> <b> ID Card</b></h3>
                <hr>
                </div>
              </div>

              
              <div class="row">              
              <div class="col-xs-12">
                <p >Please submit <strong>one</strong> of the following documents</p>
                  <div>
                    <div>
                      <div class="form-group">
                        <label for="input-69">Select Identification</label>
                        <div class="form-group">
                            <select id="identity" name="identity" v-on:change="onSelectChange($event)" v-model="key" required="yes"  class="form-control">
                            <option value="National ID">National ID</option>
                            <option value="Driver License">Driver License</option>
                            <option value="International Passport">International Passport</option>			
                            </select>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label class="">Company name (optional)</label>
                  <input type="text" name="company_name" v-model="company_name" class="form-control" >
                </div>
              </div> 

      <div class="col-xs-12">
          <div class="form-group">
            <label for="input-84"><i class="fa fa-paperclip fa_custom fa-3x"></i> Front ID Card</label>            
            <input ref="file1" v-on:change="handleFileUpload1()" required type="file" id="input-84" accept="image/*, application/pdf, application/msword, .docx">
          </div>
      </div>
      <div class="col-xs-12">
        <br>
        <div class="form-group">
          <label for="input-93"><i class="fa fa-paperclip fa_custom fa-3x"></i> Back ID Card</label>
          <input ref="file2" v-on:change="handleFileUpload2()" type="file" id="input-93" accept="image/*, application/pdf, application/msword, .docx">
        </div>
      </div>
      <br><br><br>
          <div class="col-xs-12">
          <h3><span>2: </span> <b> Utility Bill</b></h3>
          </div>
          <hr>
      <div  class="col-xs-12">
        <div class="form-group">
          <label for="input-102"><i class="fa fa-paperclip fa_custom fa-3x"></i> Utility Bill</label>
          <input ref="file3" v-on:change="handleFileUpload3()" required type="file" id="input-102" accept="image/*, application/pdf, application/msword, .docx">
        </div>
      </div>
      </div>
      
      
      </div>
    </div>

      <div class="card">
      <div class="card-body table-responsive">
        <p><span>* If you are representing a company and are not private individual &ndash; other documents need to be provided</span></p>
        <div class="row">
      <div  class="col-xs-12">
        <div class="form-group">
          <label for="input-103"><i class="fa fa-paperclip fa_custom fa-3x"></i> Other Documents Upload</label>        
          <input ref="file4" v-on:change="handleFileUpload4()" type="file" id="input-103" accept="image/*, application/pdf, application/msword, .docx" >
          <!-- accept="image/*, application/pdf, application/msword, .docx" -->
        </div>
      </div>
      <div  class="col-xs-12">
        <button  type="submit" class="btn btn-primary btn-block" :disabled="loading">Upload</button>
        <p v-if="loading">Please wait...</p>
      </div>
      <!-- v-on:click="submitFile()" -->
      </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body table-responsive">
      <p><b>All documentation is kept strictly confidential and secured.</b></p>
      <!-- <p>Please   note: Due to Vestrade's compliance with strict regulatory guidelines,   accounts that have not been verified within 14 business days from the   initial deposit may be temporarily blocked until the appropriate   documents are supplied.</p> -->
      <p>We accept the following file types: jpg, jpeg, tif, png, gif, pdf, doc, docx</p>
      <p>Maximum file size: 5MB</p>
      </div>
    </div>

    </div>
    </div>
       </div>
      </div>
      </form>
    <div class="row">
      <div class="col-xs-12">
        <div class="card">
          <div class="" style="margin-top:15px !important;">
            <h3 class="page-title">Uploaded Documents</h3>
          </div>
        <div class="card-body table-responsive" style="margin-top:15px !important; padding: 10px 50px;">        
        <div v-for="item in uploadedDocuments" v-bind:key="item.id" >
          
          <li v-if="item.company_name"><b>Company name:</b> {{ item.company_name }}</li>
          <li><b>ID Card:</b> {{ item.identity }}</li>
          <li v-if="item.file1"><a v-bind:href="item.file1" target="blank"><b>View Uploaded file</b></a></li>
          <li v-if="item.file2"><a v-bind:href="item.file2" target="blank"><b>View Uploaded file</b></a></li>
          <li v-if="item.file3"><a v-bind:href="item.file3" target="blank"><b>View Uploaded file</b></a></li>
          <li v-if="item.file4"><a v-bind:href="item.file4" target="blank"><b>View Uploaded file</b></a></li>
          
        </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <BackendScripts /> -->

</template>

<script>
import axios from "axios";
import API_URL  from './../common/config'
import Vue from "vue";
import moment from "moment";

Vue.filter("formatdate", function(value) {
 if (value) {
 return moment(String(value)).format("MM-DD-YYYY");//"MM/DD/YYYY hh:mm"
 }
});

export default {
    name: 'UploadDocuments',
    components: {
      // BackendScripts
    },
    data(){
      return {
        key: '',
        identity: '',
        company_name: '',
        file1: '',
        file2: '',
        file3: '',
        file4: '',
        uploadedDocuments: [],
        loading: false
      }
    },
    created() {
      this.getUploadedDocuments()
    },
    computed: {
      getDocument() {
        return this.$store.getters.getUploadedDocument
      }
    },
    watch: {
      getDocument() {
        this.uploadedDocuments = this.$store.getters.getUploadedDocument
      }
    },
    methods: {
      submitFile(){
        this.loading = true
            // Initialize the form data           
            let formData = new FormData();

          //  Add the form data we need to submit 
            formData.append('company_name', this.company_name);
            formData.append('identity', this.identity);
            formData.append('file1', this.file1);
            formData.append('file2', this.file2);
            formData.append('file3', this.file3);
            formData.append('file4', this.file4);
            console.log('formData1 ==', formData)

          var token = JSON.parse(localStorage.getItem('userData')).token

              axios.post(API_URL + 'upload-document/upload/', formData, {
                headers: {
                  'Authorization': `Token ${token}`
                }
              })
              .then(data => {
                  console.log(data);
                  alert('Your Document Uploading was Successful!')
                  this.getUploadedDocuments()
                  this.loading = false
              })
              .catch((error) => {
                  console.error('Error:', error);
                  this.loading = false
              });
      },
      onSelectChange(event){
        this.identity = event.target.value;
        console.log(event.target.value)
      },
      handleFileUpload1(){
        this.file1 = this.$refs.file1.files[0];
        console.log('this.file1 ==', this.file1)
      },
      handleFileUpload2(){
        this.file2 = this.$refs.file2.files[0];
         console.log('this.file2 ==', this.file2)
      },
      handleFileUpload3(){
        this.file3 = this.$refs.file3.files[0];
         console.log('this.file3 ==', this.file3)
      },
      handleFileUpload4(){
        this.file4 = this.$refs.file4.files[0];
         console.log('this.file4 ==', this.file4)
      },
      getUploadedDocuments() {
      console.log('uploadedDocuments 1=')
      this.$store.dispatch('loadUploadedDocument')      
      }
    },
}
</script>

<style scoped>
div {
  font-size: 16px !important;
}
 span {
    font-size: 16px !important;
 }
 p {
    font-size: 16px !important;
 }
 label {
   font-size: 14px !important;
 }
 button {
  font-size: 18px !important;
  padding: 10px 35px !important;
  width: 150px;
  justify-items: center;
  margin-top: 30px;
}
.fa_custom {
  color: rebeccapurple;
}
li {
  padding: 10px;
}
</style>