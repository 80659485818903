
<template>
<!-- eslint-disable -->
  <div id="home" class="home">
        <!-- <title></title> -->
        <!-- <HomeSlider /> -->
        <HomeSliderTwo />
        <section class="">
            <div class="container">
                <div class="text-center mb-80">
                    <h2 class="section-title text-uppercase" style="margin-top:25px;">Trade with a Trusted Global Broker</h2>
                    <p class="section-sub">Access the financial markets all in one place.</p>
					<p class="section-sub">On our platform, you can trade on the largest lists of assets in the industry.</p>
                </div>

              <div class="row no-gutter" style="margin-top:-50px;">
                  <div class="col-md-4 col-sm-6">
                      <div class="featured-box border-right text-center hover brand-hover">
                        <div class="featured-wrapper">
                          <div class="intro-header">
                              <i class="material-icons brand-icon">&#xE53B;</i>
                              <h2>Cryptocurrency</h2>
                          </div>

                          <div class="content" style="margin-top: -50px !important;">
                            <p><router-link :to="'/crypto-trading'" style="color:#fff;">Learn more..</router-link></p>
                          </div>
                        </div><!-- /.featured-wrapper -->
                      </div><!-- /.featured-box  -->
                  </div><!-- /.col-md-3 -->

                  <div class="col-md-4 col-sm-6">
                      <div class="featured-box border-right text-center hover brand-hover">
                        <div class="featured-wrapper">
                          <div class="intro-header">
                              <i class="material-icons brand-icon">&#xE051;</i>
                              <h2>Forex Trading</h2>
                          </div>

                          <div class="content" style="margin-top: -50px !important;">
                            <p><router-link :to="'/forex-trading'" style="color:#fff;">Learn more..</router-link></p>
                          </div>
                        </div><!-- /.featured-wrapper -->
                      </div><!-- /.featured-box  -->
                  </div><!-- /.col-md-3 -->

                  <div class="col-md-4 col-sm-6">
					<div class="featured-box border-right text-center hover brand-hover" >
					<div class="featured-wrapper">
						<div class="intro-header">
							<i class="material-icons brand-icon">&#xE323;</i>
							<h2>Binary Options</h2>
						</div>

						<div class="content" style="margin-top: -50px !important;">
						<p><router-link :to="'/binary-trading'" style="color:#fff;">Learn more..</router-link></p>
						</div>
					</div><!-- /.featured-wrapper -->
					</div><!-- /.featured-box  -->
                  </div><!-- /.col-md-3 -->                  
              </div><!-- /.row -->

            </div><!-- /.container -->
        </section>
        <!-- creative/creative-banner-2  -->
        <section id="img-section" class="section-padding bg-fixed parallax-bg overlay dark-5" data-stellar-background-ratio="0.5" width="100%">
          <div class="container" >
                <div class="text-center mb-80">
                    <h2 class="section-title white-text text-uppercase"><b>How To Get Started</b></h2>
                    <p class="section-sub" style="color:#fff !important;">It only takes a few minutes to get your account set up and ready for trading. </p>
					<br>
					<h2 class="sub-section-title white-text"><b>Follow these easy steps:</b></h2>
                </div>

                <ul class="list-inline row process-in">
                  <li class="col-sm-4">
                    <div class="process-number">
                      1
                    </div>
                    <div class="process-desc">
                      <h2 class="white-text text-medium">Open an account </h2>
                      <p style="color:#fff !important;">Register in less than a minute.</p>
                    </div>
                  </li>
                  <li class="col-sm-4">
                    <div class="process-number">
                      2
                    </div>
                    <div class="process-desc">
                      <h2 class="white-text text-medium">Deposit money</h2>
                      <p style="color:#fff !important;">When creating an account, our broker is automatically selected for you. All you have to do is deposit some money to invest.</p>
                    </div>
                  </li>
                  <li class="col-sm-4">
                    <div class="process-number">
                      3
                    </div>
                    <div class="process-desc">
                      <h2 class="white-text text-medium">Start trading</h2>
                      <p style="color:#fff !important;">Then you can relax and let trade for you! We analyzes the market and automatically finds the best opportunities for you.</p>
                    </div>
                  </li>
                </ul>

          </div>
        </section>

 	        <section class="gray-bg" style="padding-top:30px;">
            <div class="container">

              <div class="text-center mb-80">
                  <h2 class="section-title text-uppercase" style="color:#673BB7">Pricing</h2>                  
                   <img src="@/assets/img/vestrade-robot-trade.jpg" alt="">
                   <br> <br>
                   <h3 class="section-sub" style="color:#673BB7"><b>Purchase the PRO V6 ROBOT NOW !!!</b></h3>
                   <br>
              <p><strong>What is a Trading Robot?</strong><br>
              Forex/Binary Trading Robots (Expert Advisor) are software or  algorithmic scripts that can be added to the forex trading platform with the  aim that the application can later run automatically or make buy and sell  transactions without user manual instructions. Trading Robot which is also  known as EA Forex is one of the main components in Auto Trading with various  functions.</p>
              <p class="btn">
                <span class="posted-in">
                    <router-link :to="'/pro-v6'" style="color:#fff;"><b>Learn more...</b></router-link>
                </span>
                </p>
              </div>

              <div >
                <div >
                 
                  
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">
                  <div class="pricing-table">
                    <div class="table-contents text-center">
                      <div class="table-header brand-hover">

                        <div class="package-price">
                          <span class="currency-symbol">$</span>
                          <span class="price">300</span>
                          <span class="currency-code">Min Deposit</span>
                        </div>

                        <div class="package-name">
                          <span>Personal</span>
                        </div> 
                      </div> <!-- /.table-header -->

                      <div class="table-info">
                        <ul>
                          <li>100+ products</li>
                          <li>0.5pips spread form</li>
                          <li>spread type - fixed</li>
                          <li>24/7 support</li>
                          <!-- <li><del>Certificate</del></li> -->
                        </ul>                                
                      </div><!-- /.table-info -->

                      <div class="table-footer">
                        <div class="purchase-button">
                          <a @click="depositURL" class="btn btn-lg text-capitalize waves-effect waves-light" style="color:#fff;">Deposit</a>
                        </div>   
                      </div> <!-- /.table-footer -->
                    </div> <!-- /.table-contents -->
                  </div><!-- /.pricing-table -->
                </div><!-- /.col-md-4 -->

                <div class="col-md-3">
                  <div class="pricing-table">
                    <div class="table-contents text-center">
                      <div class="table-header brand-hover">

                        <div class="package-price">
                          <span class="currency-symbol">$</span>
                          <span class="price">500</span>
                          <span class="currency-code">Min Deposit</span>
                        </div>
                        <div class="package-name">
                          <span>Pro V6 Software</span>
                        </div> 

                      </div> <!-- /.table-header -->

                      <div class="table-info">
                        <ul>
                          <li>120+ products</li>
                          <li>0.7pips spread form</li>
                          <li>spread type - fixed</li>
                          <li>24/7 support</li>
                          <!-- <li><del>Certificate</del></li> -->
                        </ul>                                
                      </div><!-- /.table-info -->

                      <div class="table-footer">
                        <div class="purchase-button">
                         <a @click="depositURL" class="btn btn-lg text-capitalize waves-effect waves-light" style="color:#fff;">Deposit</a>
                        </div>   
                      </div> <!-- /.table-footer -->
                    </div> <!-- /.table-contents -->
                  </div><!-- /.pricing-table -->
                </div><!-- /.col-md-4 -->

                <div class="col-md-3">
                  <div class="pricing-table">
                    <div class="table-contents text-center">
                      <div class="table-header brand-hover">

                        <div class="package-price">
                          <span class="currency-symbol">$</span>
                          <span class="price">1000</span>
                          <span class="currency-code">Min Deposit</span>
                        </div>
                        <div class="package-name">
                          <span>Standard</span>
                        </div> 

                      </div> <!-- /.table-header -->

                      <div class="table-info">
                        <ul>
                          <li>150+ products</li>
                          <li>0.5pips spread form</li>
                          <li>spread type - variable</li>
                          <li>24/7 support</li>
                        </ul>                                
                      </div><!-- /.table-info -->

                      <div class="table-footer">
                        <div class="purchase-button">
                          <a @click="depositURL" class="btn btn-lg text-capitalize waves-effect waves-light" style="color:#fff;">Deposit</a>
                        </div>   
                      </div> <!-- /.table-footer -->
                    </div> <!-- /.table-contents -->
                  </div><!-- /.pricing-table -->
                </div><!-- /.col-md-4 -->

                <div class="col-md-3">
                  <div class="pricing-table">
                    <div class="table-contents text-center">
                      <div class="table-header brand-hover">

                        <div class="package-price">
                          <span class="currency-symbol">$</span>
                          <span class="price">1500</span>
                          <span class="currency-code">Min Deposit</span>
                        </div>
                        <div class="package-name">
                          <span>Premium</span>
                        </div> 
                      </div> <!-- /.table-header -->

                      <div class="table-info">
                        <ul>
                          <li>250+ products</li>
                          <li>1 - 0.5pips spread form</li>
                          <li>spread type - variable</li>
                          <li>24/7 support</li>
                        </ul>                                
                      </div><!-- /.table-info -->

                      <div class="table-footer">
                        <div class="purchase-button">
                          <a @click="depositURL" class="btn btn-lg text-capitalize waves-effect waves-light" style="color:#fff;">Deposit</a>
                        </div>   
                      </div> <!-- /.table-footer -->
                    </div> <!-- /.table-contents -->
                  </div><!-- /.pricing-table -->
                </div><!-- /.col-md-4 -->
              </div><!-- /.row -->
            </div><!-- /.container -->
        </section>

        <!-- start Marketing API -->
        <section style="margin-bottom: 30px; padding-top: 20px;">
          <div class="container">
            <div style="height:62px; background-color: #FFFFFF; overflow:hidden; box-sizing: border-box; border: 1px solid #56667F; border-radius: 4px; text-align: right; line-height:14px; block-size:62px; font-size: 12px; font-feature-settings: normal; text-size-adjust: 100%; box-shadow: inset 0 -20px 0 0 #56667F;padding:1px;padding: 0px; margin: 0px; width: 100%;"><div style="height:40px; padding:0px; margin:0px; width: 100%;"><iframe src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=light&pref_coin_id=1505&invert_hover=" width="100%" height="36px" scrolling="auto" marginwidth="0" marginheight="0" frameborder="0" border="0" style="border:0;margin:0;padding:0;"></iframe></div><div style="color: #FFFFFF; line-height: 14px; font-weight: 400; font-size: 11px; box-sizing: border-box; padding: 2px 6px; width: 100%; font-family: Verdana, Tahoma, Arial, sans-serif;"><a href="https://coinlib.io" target="_blank" style="font-weight: 500; color: #FFFFFF; text-decoration:none; font-size:11px">Cryptocurrency Prices</a>&nbsp;by Coinlib</div></div>
          </div>
        </section>
         <!-- END Marketing API -->

        <section class="counter-section">
              <div class="container-fluid">
                  <div class="row text-center">

                      <div class="col-sm-3 counter-wrap light-blue">
                        <strong class="white-text"><span class="timer">1500</span>+</strong>
                        <span class="count-description white-text">Trading Instruments</span>
                      </div> <!-- /.col-sm-3 -->

                      <div class="col-sm-3 counter-wrap deep-purple">
                        <strong class="white-text"><span >0.5</span></strong>
                        <span class="count-description white-text">Spreads as low as 0.5 pips</span>
                      </div><!-- /.col-sm-3 -->

                      <div class="col-sm-3 counter-wrap light-blue">
                        <strong class="white-text"><span class="timer">50</span>+</strong>
                        <span class="count-description white-text">Countries supported</span>
                      </div><!-- /.col-sm-3 -->

                      <div class="col-sm-3 counter-wrap deep-purple">
                        <strong class="white-text">$<span class="timer">5000</span></strong>
                        <span class="count-description white-text">Bonus</span>
                      </div><!-- /.col-sm-3 -->
                  </div>
              </div><!-- /.container -->
        </section>

        <section class="gray-bg" style="padding-top: 30px;">
            <div class="container">

	            <div class="text-center mb-80">
	                <h2 class="section-title text-uppercase">why chose us</h2>
	                <p class="section-sub">There is a reason why over 3.5 Million Clients Choose Us!</p>             
                  
              </div>
              <div class="row">
                <div class="col-md-5">
                  <img src="@/assets/img/woman.png" alt="">
                </div>
                <div class="col-md-7">
                <div class="panel-group feature-accordion purple-accordion icon angle-icon" id="accordion-one">
                    
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          <a data-toggle="collapse" data-parent="#accordion-one" href="#collapse-one">
                            Good Performance
                          </a>
                        </h3>
                      </div>
                      <div id="collapse-one" class="panel-collapse collapse in">
                        <div class="panel-body">
                          Our platforms are fast and our signals are accurate, we give you live data on the market.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion-one" href="#collapse-two">
                            Awesome Business Growth
                          </a>
                        </h3>
                      </div>
                      <div id="collapse-two" class="panel-collapse collapse">
                        <div class="panel-body">
                          Our approach to brokage isn't fixed, we grow our business to suit you.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion-one" href="#collapse-three">
                            Creative Technology Support
                          </a>
                        </h3>
                      </div>
                      <div id="collapse-three" class="panel-collapse collapse">
                        <div class="panel-body">
                          Simplicity is key, that's why we have done all the hard job so you can trade without stress.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion-one" href="#collapse-four">
                            Globally Renowned
                          </a>
                        </h3>
                      </div>
                      <div id="collapse-four" class="panel-collapse collapse">
                        <div class="panel-body">
                          We have clients from over 200 countries and staff speaking over 35 languages. Our management has visited over 150 cities globally to understand clients’ and partners’ needs.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion-one" href="#collapse-five">
                            Range of Trading Instruments
                          </a>
                        </h3>
                      </div>
                      <div id="collapse-five" class="panel-collapse collapse">
                        <div class="panel-body">
                          Our clients can choose to trade Cryptocurrency, Forex Trading, Binary Options, etc and energies from the same trading account. With a wide range of trading instruments available from a single multi asset platform, we makes trading easier and efficient.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion-one" href="#collapse-six">
                            Focused on the Client
                          </a>
                        </h3>
                      </div>
                      <div id="collapse-six" class="panel-collapse collapse">
                        <div class="panel-body">
                          Size does not matter. The client comes first regardless of net capital worth, account type or size of investment. All our clients receive the same quality services, the same execution, and the same level of support. We are founded on these values, and that will not change.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion-one" href="#collapse-seven">
                            Transparent and Fair
                          </a>
                        </h3>
                      </div>
                      <div id="collapse-seven" class="panel-collapse collapse">
                        <div class="panel-body">
                          We what you see is what you get, with no hidden terms. Be that pricing, execution or promotions. What we advertise is what we give our clients, regardless of the size of their investment.
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion-one" href="#collapse-eight">
                            Easy and Convenient
                          </a>
                        </h3>
                      </div>
                      <div id="collapse-eight" class="panel-collapse collapse">
                        <div class="panel-body">
                          All our systems are built and updated with the client in mind. Starting from our account opening procedure, to managing your account, depositing or withdrawing funds and finally trading, it’s all straightforward simple and easy to use for all our clients.
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion-one" href="#collapse-nine">
                            What is a Trading Robot?
                          </a>
                        </h3>
                      </div>
                      <div id="collapse-nine" class="panel-collapse collapse">
                        <div class="panel-body">
                          Forex/Binary Trading Robots (Expert Advisor) are software or  algorithmic scripts that can be added to the forex trading platform with the  aim that the application can later run automatically or make buy and sell  transactions without user manual instructions. Trading Robot which is also  known as EA Forex is one of the main components in Auto Trading with various  functions.
                        </div>
                      </div>
                    </div>
                    
                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion-one" href="#collapse-ten">
                            What Can Forex/Binary Trading Robot (EA) Do?
                          </a>
                        </h3>
                      </div>
                      <div id="collapse-ten" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>
                          The functions of the Forex/Binary Trading Robot vary, depending on  how the application settings are used by traders. Generally, EA can be  programmed to do the following:</p>
                          <p>1 Open position (Buy, Sell) or Close position.</p>
                          <p>2 Change the Stop Loss and Profit Target of a trading  position that is running.</p>
                          <p>3 Analyze a currency pair based on certain technical  indicator parameters that we can set ourselves.</p>
                          <p>4 Send alerts in the form of voice, email, API (integration  to other applications).</p>
                          <p>5 Analyze the financial management of our account. Balance,  margin, etc.</p>
                          <p>With the ability to integrate into other applications, the  potential for EA development is unlimited. However, &nbsp;Forex/Binary Trading Robots only carry out tasks  (trading) in accordance with the programs in them. A Trading Robot will not  'betray' out of the path of a predetermined system.</p>
                          <p>So, a great EA is definitely a translation result of a  proven and reliable trading system. The more powerful the programmer applies to  the language of the robot, the more results that get closer to reality will be  obtained.</p>
                        </div>
                      </div>
                    </div>

                    <div class="panel panel-default">
                      <div class="panel-heading">
                        <h3 class="panel-title">
                          <a class="collapsed" data-toggle="collapse" data-parent="#accordion-one" href="#collapse-eleven">
                            How To Make Forex/Binary Trading Robot (EA)? 
                          </a>
                        </h3>
                      </div>
                      <div id="collapse-eleven" class="panel-collapse collapse">
                        <div class="panel-body">
                          <p>Each forex trading platform will have its own EA programming  language. Among the most popular programming languages &#8203;there are three:</p>
                          <p>&nbsp;&nbsp;&nbsp;*MQL4 programming language for the MT4  platform.</p>
                          <p>&nbsp;&nbsp;&nbsp;*MQL5 programming language for the MT5  platform.</p>
                          <p>&nbsp;&nbsp;&nbsp;*C # programming language for the cTrader  platform.</p>
                          <p>MQL and C # have programming structures like in general  (Pascal, C, etc.). So, if you already have experience in computer programming,  it will certainly be easy to master it.</p>
                        </div>
                      </div>
                    </div>

                  </div>  
              </div>
              </div>
            </div><!-- /.container -->
        </section>

        <section class="section-padding" style="background-color:#EFF2F4; margin-top: -30px !important; margin-bottom: -30px !important;" >
          <div class="container" style="margin-top: -30px !important; margin-bottom: -30px !important;">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12" align="center">
              <img src="@/assets/img/vestrade-approved-trading.jpg" alt="" width="85%" align="center">
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12" align="center">
                <div v-for="video in videoUrl" v-bind:key="video.id" style="margin-top:25px;">
                  <video v-if="video.video_type == 'Home'" :src="video.video_file" controls width="85%"  align="center"></video>
                </div>
              </div>
          </div>
          </div>
        </section>
       <section class="section-padding" style="background-color:#fff;">
          <div class="container">
              <div class="text-center mb-80">
                  <h2 class="section-title text-uppercase">Our Happy Customers</h2>
                  <p class="section-sub">Hear what our customers say about us..</p>
              </div>

              <div class="row" style="margin-top: -60px;">
                <div class="col-md-10 col-md-offset-1">

                  <div class="thumb-carousel square-thumb text-center">
                    <ul class="slides">
                      <li data-thumb="assets/img/client-thumb/1.png">
                        
                        <div class="content">
                            <p>I’ve been trading on VesTrade for a while now, and I think it’s very appealing to traders who are just starting out. It’s easy to understand and all my trading information is very accessible.</p>

                            <div class="testimonial-meta">
                                Herndon Ronald
                                <span>User</span>
                            </div>
                        </div>
                      </li>
                      <li data-thumb="assets/img/client-thumb/2.png">
                        
                        <div class="content">
                            <p>This platform is fast, easy to navigate, and very user-friendly. It looks great and it’s packed with many appealing features. Deposits and withdrawals are easy.</p>

                            <div class="testimonial-meta">
                                Rose Malthana
                                <span>User</span>
                            </div>
                        </div>
                      </li>
                      <li data-thumb="assets/img/client-thumb/3.png">
                        
                        <div class="content">
                            <p>What I like most is that my withdrawals are processed fast. This is the platform of the future: it offers more functionality as well as different ways to trade. No other broker has given me the same satisfaction as this platform has. A great broker indeed.</p>

                            <div class="testimonial-meta">
                                John Dave
                                <span>User</span>
                            </div>
                        </div>
                      </li>
                      <li data-thumb="assets/img/client-thumb/4.png">
                        
                        <div class="content">
                            <p>I have more than a decade’s worth of online trading experience, and I think that VesTrade is one of the best brokers in the world. I like the new features on this platform.</p>

                            <div class="testimonial-meta">
                                Clarie Manikandan
                                <span>User</span> 
                            </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                </div>
              </div><!-- /.row -->

          </div><!-- /.container -->
        </section>

  </div>
</template>

<script>
import axios from "axios";
import API_URL  from './../common/config'

/* eslint-disable */
document.title = 'Welcome to VesTrade | Cryptocurrency, Forex, Binary Option Trading | www.vestrade.com';
// import HomeSlider from '../components/Partials/home-slider'
import HomeSliderTwo from '../components/Partials/home-slider2'
export default {
  name: 'Home',
  components: {
    HomeSliderTwo
    // HomeSlider
  },
  data() {
    return {
      videoUrl: null,
    };
  },
  props: {
    msg: String
  },
  mounted() {
    this.fetchVideo();
  },
  methods: {
    depositURL(){
      // alert('== Go to Deposit ==')
      if(this.$store.getters.user){
        //console.log("== user is login ")
        this.$router.push('/user/user-deposit-funds')
      } else {
        //console.log("== user not login ",  window.location.reload)
        this.$router.push('/login')
        //window.location.reload("/login")
      }
    },
    fetchVideo() {
      axios.get(API_URL + 'pages/videos/').then(response => {
        this.videoUrl = response.data;
        //console.log('== response.data ', response.data)
        // this.$refs.videoPlayer.play();
      }).catch(error => {
        console.log(error);
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-sub {
	color: black !important;
  
}
#img-section {
  background-image: url(../assets/img/creative/creative-banner-2.jpg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.purchase-button a {
  font-size: 20px !important;
}
  .package-price {
    margin-top: -50px !important;
  }
  .package-name {
    margin-top: 60px !important;
    padding-bottom: 20px !important;
  }
</style>
