<template>
  <div>
    <link href="assets/css/shortcodes/login.css" rel="stylesheet">
<!--page title start-->
        <section class="page-title page-title-center cover-4 padding-top-110 padding-bottom-60 overlay purple-5 fixed-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="white-text font-20 text-bold" style="margin-top:45px;">Login / Signup</h4>
                    <!--     <ol class="breadcrumb">
                            <li><a href="#">Home</a></li>
                            <li class="active">Login/Signup</li>
                        </ol> -->
                    </div>
                </div>
            </div>
        </section>
        <!--page title end-->
     <section class="section-padding gray-bg">
            <div class="container">

                <div class="login-wrapper">
                  <div class="card-wrapper"></div>                  
                   <div class="card-wrapper">
                   <div class="logForm"  style="display:block;">
                    <h1 class="title">Login</h1>
                    <form @submit.prevent="onSignin">
                      <div class="input-container">
                        <input type="text" id="username" name="username" v-model="username" required="required" autocomplete="off" />
                        <label for="username">Username</label>
                        <div class="bar"></div>
                      </div>
                      <div class="input-container">
                        <input type="password" id="password" name="password" v-model="password" required="required" autocomplete="off" />
                        <label for="password">Password</label>
                        <div class="bar"></div>
                      </div>
                      <div class="button-container">
                        <button type="submit" class="btn btn-lg btn-block waves-effect waves-light"><b>Login</b></button>
                      <div v-if="setLoading">Loading...</div>
                      </div>
                      <div class="forgot-password" align="center"><div align="center">
                        <a  
                            :href="href"
                        
                            target="blank" align="center" >Forgot your password?</a>
                          <!-- href="http://127.0.0.1:8000/password-reset/" -->
                          <!-- href="https://vestrading.wiseclub2k.com/password-reset/" -->

                        </div> </div>
                    </form>
                    <div style="padding-top:80px; align:center;" align="center" >
                      <hr><br>
                      <a class="btn waves-effect waves-red" 
                        style="magin-top:150px; color:#fff;" @click="onToggle"><b>Register</b></a>
                    </div>
                    </div>
                    
                  </div>
                  <div class="card-wrapper alt">
                    <div class="toggle" @click="onToggle"></div>
                     </div>
                     <div class="card-wrapper">
                  
                  <div class="regForm" style="display:none;">
                     <p class="closes" @click="onClosed">X</p>
                    <h1 class="title">Register
                      
                    </h1>
                    <form @submit.prevent="onSignup">
                      <div class="input-container">
                        <input type="text" id="newUsername" name="newUsername" v-model="newUsername" required="required" autocomplete="off" />
                        <label for="newusername">Username</label>
                        <div class="bar"></div>
                      </div>
                      <div class="input-container">
                        <input type="email" id="newEmail" name="newEmail" v-model="newEmail" required="required" autocomplete="off" />
                        <label for="newusername">Email</label>
                        <div class="bar"></div>
                      </div>
                      <div class="input-container">
                        <input type="password" id="newPassword" name="newPassword" v-model="newPassword" required="required" autocomplete="off" />
                        <label for="newpassword">Password</label>
                        <div class="bar"></div>
                      </div>
                      <div class="input-container">
                        <input type="password" id="repeatRassword" name="repeatRassword" v-model="repeatRassword" 
                         required="required" autocomplete="off" />
                        <label for="repeat-password">Repeat Password</label>
                        <div class="bar"><span v-text="comparePasswords"></span></div>
                      </div>
                      <div class="button-container">
                        <button type="submit" class="btn btn-lg btn-block white waves-effect waves-red"><b>Register</b></button>
                       <div v-if="setLoading">Loading...</div>
                      </div>
                    </form>
                    <div style="padding-top:80px; align:center;" align="center">
                      <hr><br>
                      <a class="btn waves-effect waves-red" 
                        style="magin-top:150px; color:#fff;" @click="onClosed"><b>Login</b></a>
                       
                    </div>
                    
                    </div>
                    
                  </div>
                </div>

            </div>
        </section>

  </div>
</template>

<script>
import PASSWORD_API_URL from '../../common/forget-password-url';

export default {
    name: "Login",
    data () {
        return {
            newUsername:'',
            newEmail: '',
            newPassword: '',
            repeatRassword: '',
            username: '',
            password: '',
            href: PASSWORD_API_URL + '/password-reset/',
            setLoading: false
        }
    },
    computed: {
      comparePasswords () {
        return this.newPassword !== this.repeatRassword ? 'Passwords do not match' : ''
      },
      user () {
        return this.$store.getters.user
      },
      token () {
        return this.$store.getters.token
      }
    },
    watch: {
      user (value) {
        if (value !== null && value !== undefined) {
          console.log('token 1=')
          this.$router.push('/user')
        }
      },
      token (value) {
       
        console.log('token 2=', value)
    
      }
    },
    methods: {
        onToggle(){
            // console.log('== is toggled ==')
            document.querySelector(".logForm").style.display= 'none';
            document.querySelector(".regForm").style.display= 'block';
            document.querySelector(".toggle").style.display= 'none';
            document.querySelector(".closes").style.display= 'block';
        },
        onClosed(){
            //  console.log('== is closes ==')
            document.querySelector(".logForm").style.display= 'block';
            document.querySelector(".regForm").style.display= 'none';
            document.querySelector(".closes").style.display= 'none';
            document.querySelector(".toggle").style.display= 'block';
        },
        onSignup () {
          // console.log({username: this.newUsername, email: this.newEmail, password: this.newPassword})
          this.setLoading = true
          this.$store.dispatch('signUserUp', {username: this.newUsername, email: this.newEmail, password: this.newPassword})
        },
        onSignin () {
        // console.log({username: this.username, password: this.password})
        this.setLoading = true
        this.$store.dispatch('signUserIn', {username: this.username, password: this.password})
      },
    },
}

</script>


<style scoped>

.regForm{
    margin-top: -60px !important;
}
.closes{


  background: #ed2553;
    width: 80px;
    height: 80px;
    margin-top: -80px !important;
    margin-left: 80%;

    color: antiquewhite;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  text-align: center;
  font-weight: bold;
    font-size: 30px;
    line-height: 80px;
  cursor: pointer;
}

.forgot-password {
  margin-top: 20px;
}

</style>