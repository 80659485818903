var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('footer',{staticClass:"footer footer-one"},[_c('div',{staticClass:"primary-footer brand-bg"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-4 widget"},[_c('h2',{staticClass:"white-text"},[_vm._v("Contact Us")]),_c('ul',{staticClass:"footer-list"},[_vm._l((_vm.address),function(contact){return _c('li',{key:contact.id},[_c('strong',[_vm._v("Address :")]),_vm._v(" "+_vm._s(contact.address))])}),_c('br'),_vm._l((_vm.phone_number),function(phone){return _c('li',{key:phone.id},[_c('strong',[_vm._v("Call or SMS")]),_vm._v(" "),_c('strong',[_vm._v(":")]),_vm._v(" "+_vm._s(phone.phone_number))])}),_c('br'),_vm._l((_vm.whatsapp_number),function(whatsapp){return _c('li',{key:whatsapp.id},[_c('strong',[_vm._v("Whatsapp Us")]),_vm._v(" "),_c('strong',[_vm._v(":")]),_vm._v(" "+_vm._s(whatsapp.whatsapp_number))])}),_c('br'),(_vm.telegram_number)?_c('span',[_vm._l((_vm.telegram_number),function(telegram){return _c('li',{key:telegram.id},[_c('strong',[_vm._v("Telegram")]),_vm._v(" "),_c('strong',[_vm._v(":")]),_vm._v(" "+_vm._s(telegram.telegram_number))])}),_c('br')],2):_vm._e(),_vm._l((_vm.email_address),function(email){return _c('li',{key:email.id},[_c('strong',[_vm._v("Email")]),_vm._v(" : "+_vm._s(email.email_address))])}),_c('br'),_vm._m(2)],2)]),_c('div',{staticClass:"col-md-4 widget"},[_vm._m(3),_c('hr'),_vm._l((_vm.whatsapp_number),function(whatsapp){return _c('div',{key:whatsapp.id},[_c('a',{staticClass:"float",attrs:{"href":`https://api.whatsapp.com/send?phone=` +  whatsapp.whatsapp_number  + `&text=How can we help you?`,"target":"_blank"}},[_c('i',{staticClass:"fa fa-whatsapp my-float"})])])})],2)])])]),_vm._m(4)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"page-scroll btn-floating btn-large pink back-top waves-effect waves-light",attrs:{"href":"#top","data-section":"#top"}},[_c('i',{staticClass:"material-icons"},[_vm._v("")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 widget clearfix"},[_c('p',[_c('img',{staticStyle:{"float":"right"},attrs:{"src":"assets/img/card-app.png","alt":"card app copy","width":"90%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('strong',[_vm._v("Monday - Friday :")]),_vm._v(" 9:00 am - 10:00 pm "),_c('br'),_vm._v(" "),_c('strong',[_vm._v("Saturday - Sunday :")]),_vm._v(" Closed")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{staticStyle:{"float":"left"},attrs:{"src":"assets/img/app-card.png","alt":"app card","width":"90%","height":"NaN"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"secondary-footer brand-bg darken-2"},[_c('div',{staticClass:"container"},[_c('span',{staticClass:"copy-text"},[_vm._v("Copyright © 2020 "),_c('a',{attrs:{"href":"#"}},[_vm._v("VesTrade")]),_vm._v("   |   All Rights Reserved.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"preloader"}},[_c('div',{staticClass:"preloader-position"},[_c('img',{attrs:{"src":"assets/img/logo-colored.png","alt":"logo"}}),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"indeterminate"})])])])
}]

export { render, staticRenderFns }