<template>
  <div id="referal-signup">
    <link href="assets/css/shortcodes/login.css" rel="stylesheet">
<!--page title start-->
        <section class="page-title page-title-center cover-4 padding-top-110 padding-bottom-60 overlay purple-5 fixed-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="white-text font-20 text-bold" style="margin-top:45px;">Signup</h4>
                    </div>
                </div>
            </div>
        </section>
        <!--page title end-->
     <section class="section-padding gray-bg">
            <div class="container">

                <div class="login-wrapper">
                  <div class="card-wrapper"></div>                  
                   <div class="card-wrapper">
                   <div class="logForm"  style="display:block;">
                    <h1 class="title">Register</h1>
                    <form @submit.prevent="onSignup">
                      <div class="input-container">
                        <input type="text" id="newUsername" name="newUsername" v-model="newUsername" required="required" autocomplete="off" />
                        <label for="newusername">Username</label>
                        <div class="bar"></div>
                      </div>
                      <div class="input-container">
                        <input type="email" id="newEmail" name="newEmail" v-model="newEmail" required="required" autocomplete="off" />
                        <label for="newemail">Email</label>
                        <div class="bar"></div>
                      </div>
                      <div class="input-container">
                        <input type="password" id="newPassword" name="newPassword" v-model="newPassword" required="required" autocomplete="off" />
                        <label for="newpassword">Password</label>
                        <div class="bar"></div>
                      </div>
                      <div class="input-container">
                        <input type="password" id="repeatRassword" name="repeatRassword" v-model="repeatRassword" 
                         required="required" autocomplete="off" />
                        <label for="repeat-password">Repeat Password</label>
                        <div class="bar"><span v-text="comparePasswords"></span></div>
                      </div>
                      <div class="input-container">
                        <label >Referral Code</label>
                        <br>
                        <input id="referralCode" name="referralCode" v-model="referralCode" readonly="readonly" required="required" autocomplete="off" />
                        <div class="bar"></div>
                      </div>
                      <div class="button-container">
                        <button type="submit" class="btn btn-lg btn-block white waves-effect waves-red"><b>Registar</b></button>
                      </div>
                    </form>
                    </div>
                    
                  </div>
                </div>

            </div>
        </section>

  </div>
</template>

<script>
export default {
    name: "ReferalSignup",
    data () {
        return {
            newUsername:'',
            newEmail: '',
            newPassword: '',
            repeatRassword: '',
            referralCode: this.$route.params.code,
        }
    },
    computed: {
      comparePasswords () {
        return this.newPassword !== this.repeatRassword ? 'Passwords do not match' : ''
      },
      user () {
        return this.$store.getters.user
      },
      token () {
        return this.$store.getters.token
      }
    },
    watch: {
      user (value) {
        if (value !== null && value !== undefined) {
          console.log('token 1=')
          this.$router.push('/user')
        }
      },
      token (value) {
        console.log('token 2=', value)
      }
    },
    methods: {
        onSignup () {
          console.log({username: this.newUsername, email: this.newEmail, password: this.newPassword, referralCode: this.referralCode})
          this.$store.dispatch('signUserUpReferral', {username: this.newUsername, email: this.newEmail, password: this.newPassword, referralCode: this.referralCode})
        },
    },
}

</script>


<style scoped>

.regForm{
    margin-top: -60px !important;
}
.closes{


  background: #ed2553;
    width: 80px;
    height: 80px;
    margin-top: -80px !important;
    margin-left: 80%;

    color: antiquewhite;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  text-align: center;
  font-weight: bold;
    font-size: 30px;
    line-height: 80px;
  cursor: pointer;
}

.forgot-password {
  margin-top: 20px;
}

</style>