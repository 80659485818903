<template>
  <div class="home-slider-two">
<!-- start revolution slider 5.0 -->

        <section class="banner-wrapper parallax-bg banner-13 fullscreen-banner valign-wrapper overlay " data-stellar-background-ratio="0.5">
            <div class="valign-cell">
              <div class="container">
                <div class="text-center padding-top-110">
                    <h1 class="tt-headline clip is-full-width" style="color:white !important;">
                    <!-- you can use "rotate-1", "letters type", "letters rotate-2", "letters rotate-3", "letters scale", "loading-bar", "slide", "clip is-full-width", "push" --> 
                        <span>We're Expert in </span>
                        <span class="tt-words-wrapper">
                            <b class="is-visible">Cryptocurrency Trading</b>
                            <b>Forex Trade</b>
                            <b>Binary Trade</b>
                        </span>
                    </h1>

                    <span class="tt-sub-intro">Trade with Us</span>
                    <router-link v-if="!LoginNavGuard" :to="'/login'" class="btn btn-primary pink waves-effect waves-light" style="font-size:24px !importantl"><b>Login / Signup Now!</b></router-link>
                    <router-link v-if="LoginNavGuard" :to="'/user'" class="btn btn-primary pink waves-effect waves-light" style="font-size:24px !importantl"><b>Dashboard</b></router-link>
                </div> 
              </div><!-- /.container -->
            </div><!-- /.valign-cell -->

            <div class="mouse-icon hidden-sm hidden-xs">
                <div class="wheel"></div>
            </div>
        </section>
 
  </div>
</template>

<script>

/* eslint-disable */

export default {
    name: "HomeSliderTwo",
    data () {
    return {
      LoginNavGuard: false,
      LoginNavGuardAdmin: false
    }
  },
  created () {
    this.isLoginMenu()
  },
  methods: {
    isLoginMenu () {
      console.log('= checkLogin 1 =', localStorage.getItem('userData'))
        if (localStorage.getItem('userData')) {
          if(JSON.parse(localStorage.getItem('userData')).is_admin) {
            this.LoginNavGuardAdmin = true
            console.log('= LoginNavGuardAdmin 1=', this.LoginNavGuardAdmin)
            return this.LoginNavGuard
          } else {
          this.LoginNavGuard = true
          console.log('= LoginNavGuard 1=', this.LoginNavGuard)
            return this.LoginNavGuard
          }
        }else {
        console.log('= LoginNavGuard 2=', this.LoginNavGuard)
        return this.LoginNavGuard
        }
    },
    onLogout () {
        this.$store.dispatch('logout')
        console.log('== home logging out')       
      },
    }

}

</script>

<style>

</style>vue