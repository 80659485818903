<template>
  <div id="contact-us">
        <!--page title start-->
        <section class="page-title page-title-center cover-4 padding-top-120 padding-bottom-60 overlay purple-5 fixed-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="white-text font-40 text-bold">Contact Us</h2>
                        <ol class="breadcrumb">
                            <li><a href="#">Home</a></li>
                            <li class="active">Contact Us</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <!--page title end-->




        <!-- map-section -->
        <!-- <section id="myMap" class="height-350"></section> -->
        <!-- /.map-section -->

        
        <!-- contact-form-section -->
        <section class="section-padding" style="margin-top:-70px;">
          
          <div class="container">
            <div class="row">
                <div class="col-md-4 contact-info">

                    <address>
                      <i class="material-icons brand-color">&#xE8B4;</i>
                      <div class="address">
                        <p v-for="contact in address" v-bind:key="contact.id">{{ contact.address }}</p>
                      </div>
                      <i class="material-icons brand-color">&#xE61C;</i>
                      <div class="phone">
                        <p><span v-for="phone in phone_number" v-bind:key="phone.id"><b>Call or SMS:</b> {{ phone.phone_number }}</span><br>
                          <span v-for="whatsapp in whatsapp_number" v-bind:key="whatsapp.id"><b>Whatsapp Us:</b> {{ whatsapp.whatsapp_number }}</span>
                          <span v-if="telegram_number"><br>
                          <span v-for="telegram in telegram_number" v-bind:key="telegram.id"><b>Telegram:</b> {{ telegram.telegram_number }}</span></span>
                        </p>
                      </div>

                      <i class="material-icons brand-color">&#xE0B7;</i>
                      <div class="mail">
                        <p><span v-for="email in email_address" v-bind:key="email.id"><a :href="`mailto:` +'email.email_address'">{{ email.email_address }}</a></span><br>
                        <a href="http://www.vestrade.com">www.vestrade.com</a></p>
                      
                        <hr>
                        <p class="mail"><a>
                          Monday - Friday : 9:00 am - 10:00 pm<br>
                          Saturday - Sunday : Closed</a></p>
                      </div>
                      
                    </address>
                </div><!-- /.col-md-4 -->

                <div class="col-md-8">
                    <form name="contact-form" id="contactForm" method="POST">

                      <div class="row">
                        <div class="col-md-6">
                          <div class="input-field">
                            <input type="text" name="name" class="validate" id="name">
                            <label for="name">Name</label>
                          </div>

                        </div><!-- /.col-md-6 -->

                        <div class="col-md-6">
                          <div class="input-field">
                            <label class="sr-only" for="email">Email</label>
                            <input id="email" type="email" name="email" class="validate" >
                            <label for="email" data-error="wrong" data-success="right">Email</label>
                          </div>
                        </div><!-- /.col-md-6 -->
                      </div><!-- /.row -->

                      <div class="row">
                        <div class="col-md-6">
                          <div class="input-field">
                            <input id="phone" type="tel" name="phone" class="validate" >
                            <label for="phone">Phone Number</label>
                          </div>
                        </div><!-- /.col-md-6 -->

                        <div class="col-md-6">
                          <div class="input-field">
                            <input id="website" type="text" name="website" class="validate" >
                            <label for="website">Your Website</label>
                          </div>
                        </div><!-- /.col-md-6 -->
                      </div><!-- /.row -->

                      <div class="input-field">
                        <textarea name="message" id="message" class="materialize-textarea" ></textarea>
                        <label for="message">Message</label>
                      </div>

                      <button type="submit" name="submit" class="waves-effect waves-light btn brand-bg pull-right mt-30 btn-block">Send Message</button>
                    </form>
                </div><!-- /.col-md-8 -->

            </div><!-- /.row -->
          </div>

        </section>
        <!-- contact-form-section End -->

  </div>
</template>

<script>
import axios from "axios";
import API_URL  from './../common/config'

export default {
    name: "ContactUs",
    data () {
    return {
      phone_number: '',
      whatsapp_number: '',
      address: '',
      email_address: '',
      telegram_number: null,
    }
  },
  created () {
    this.addressDetails()
  },
  methods: {
    addressDetails () {
      axios.get(API_URL + 'pages/phone_number/')
        .then(res => {
          this.phone_number = res.data
          // console.log('== this.phone_number ', this.phone_number)
        })
        axios.get(API_URL + 'pages/whatsapp_number/')
        .then(res => {
          this.whatsapp_number = res.data
          // console.log('== this.whatsapp_number ', this.whatsapp_number)
        })
        axios.get(API_URL + 'pages/telegram_number/')
        .then(res => {
          if(res.data.length > 0){
            this.telegram_number = res.data
            //console.log('== this.telegram_number ', this.telegram_number, res.data.length)
          }
        })
        axios.get(API_URL + 'pages/address/')
        .then(res => {
          this.address = res.data
          // console.log('== this.address ', this.address)
        })
        axios.get(API_URL + 'pages/email_address/')
        .then(res => {
          this.email_address = res.data
          // console.log('== this.email_address ', this.email_address)
        })
    }
    }
}
</script>

<style>

</style>