<template ref="parentContainer">
  <div id="frontend container-scroller">
    <body id="top" class="has-header-search">
     <Navbar @homeScrolToTop="homeScrolToTopFunc" />
    <router-view :key="$route.path"></router-view>
    <Footer />
  </body>
  </div>
  
</template>

<script>
import Navbar from '../components/Partials/nav'
import Footer from '../components/Partials/footer'

export default {
    name: 'Frontend',
  components: {
    Navbar, Footer
  },
  created(){
    this.scrollToTop()
  },
  methods: {
    homeScrolToTopFunc(value){
    if(value){
      this.scrollToTop()
    }
  },
    scrollToTop() {
        this.$nextTick(() => {
          const parentContainer = this.$refs.parentContainer;
          parentContainer.scrollTop = 0;
        });
      },
  }
}
</script>

<style>
.page-section{
  padding: 30px;
}
.home-container{
  margin: 12px 25px !important;
  padding: 5px;
}
.content_text p {
  margin: 0;
}
</style>