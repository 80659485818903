<template>
  <div id="regulation-page">
    <!--page title start-->
        <section id="regulation-page" class="page-title page-title-center cover-4 padding-top-110 padding-bottom-60 overlay purple-5 fixed-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="white-text font-20 text-bold" style="margin-top:45px;">Regulation</h4>
                    </div>
                </div>
            </div>
        </section>
        <!--page title end-->
        <section class="section-padding">
            <div class="container">
                <br>
                <h1>Regulation</h1>
                <div class="" style="text-align: justify;">
                <p><strong>LICENCE</strong></p>
                <p>Vestrade is licensed and regulated by the Cyprus Securities and Exchange Commission (CySEC) under license number 2708/07.</p>
                <p>Vestrade is licensed by CySEC to offer the following Investment and Ancillary Services:</p>
                <ul class="check-circle-list">
                <li><i class="fa fa-check-circle mr-10"></i> Reception and transmission of orders in relation to one or more financial instruments</li>
                <li><i class="fa fa-check-circle mr-10"></i> Execution of Orders on Behalf of Clients</li>
                <li><i class="fa fa-check-circle mr-10"></i> Safekeeping and administration of financial instruments, including custodianship and related services</li>
                <li><i class="fa fa-check-circle mr-10"></i> Granting credits or loans to one or more financial instruments, where the firm granting the credit or loan is involved in the transaction</li>
                <li><i class="fa fa-check-circle mr-10"></i> Foreign exchange services where these are connected to the provision of investment services</li>
                </ul>
                </div>
            </div>
        </section>
      
  </div>
</template>

<script>
export default {
    name: 'RegulationPage'
}
</script>

<style>
    p {
        color: black;
    }
    li {
         color: black;
    }
</style>