<template>
  <div class="home-slider">
<!-- start revolution slider 5.0 -->

        <!-- start revolution slider 5.0 -->
        <section class="rev_slider_wrapper fullscreen-banner valign-wrapper" data-stellar-background-ratio="0.5">
        <!-- <section class="rev_slider_wrapper" data-stellar-background-ratio="0.5"> -->
         <div class="rev_slider materialize-slider" >
          <ul>

            <!-- slide 1 start --> 
            <li data-transition="zoomin" data-slotamount="7"  data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed="2000"  data-thumb="assets/img/banner/banner-18.jpg"  data-rotate="0"  data-saveperformance="off"  data-title="Deep Forest" data-description="">

                <!-- MAIN IMAGE -->
                <img src="assets/img/banner/banner-18.jpg"  alt=""  data-bgposition="center center" data-kenburns="on" data-duration="10000" data-ease="Linear.easeNone" data-scalestart="100" data-scaleend="120" data-rotatestart="0" data-rotateend="0" data-offsetstart="0 -500" data-offsetend="0 500" data-bgparallax="10" class="rev-slidebg" data-no-retina>
                <!-- LAYERS -->

                <div class="tp-overlay"></div>

                <!-- LAYER NR. 1 -->
                <div class="tp-caption NotGeneric-Title tp-resizeme rs-parallaxlevel-0" 
                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
                    data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" 
                    data-fontsize="['70','70','70','45']"
                    data-lineheight="['70','70','70','50']"
                    data-width="none"
                    data-height="none"
                    data-whitespace="nowrap"
                    data-transform_idle="o:1;"
         
                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power2.easeInOut;" 
                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                    data-mask_in="x:0px;y:[100%];" 
                    data-mask_out="x:inherit;y:inherit;" 
                    data-start="1000" 
                    data-splitin="none" 
                    data-splitout="none" 
                    data-responsive_offset="on" 

                    
                    style="z-index: 5; white-space: nowrap;padding:10px 20px 10px 20px;">LIKE IT?
                </div>

                <!-- LAYER NR. 2 -->
                <div class="tp-caption rev-subheading white-text tp-resizeme rs-parallaxlevel-0" 
                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
                    data-y="['middle','middle','middle','middle']" data-voffset="['62','62','62','61']" 
                    data-width="none"
                    data-height="none"
                    data-whitespace="nowrap"
                    data-transform_idle="o:1;"
         
                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" 
                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                    data-mask_in="x:0px;y:[100%];s:inherit;e:inherit;" 
                    data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;" 
                    data-start="1500" 
                    data-splitin="none" 
                    data-splitout="none" 
                    data-responsive_offset="on" 

                    
                    style="z-index: 6; white-space: nowrap;">PURCHASE MATERIALIZE TODAY
                </div>

                <!-- LAYER NR. 3 -->
                <div class="tp-caption NotGeneric-Icon  tp-resizeme rs-parallaxlevel-0" 
                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
                    data-y="['middle','middle','middle','middle']" data-voffset="['-68','-68','-68','-68']" 
                    data-width="none"
                    data-height="none"
                    data-whitespace="nowrap"
                    data-transform_idle="o:1;"
                    data-style_hover="cursor:default;"
         
                     data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:1500;e:Power4.easeInOut;" 
                     data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                     data-mask_in="x:0px;y:[100%];" 
                     data-mask_out="x:inherit;y:inherit;" 
                    data-start="2000" 
                    data-splitin="none" 
                    data-splitout="none" 
                    data-responsive_offset="on" 

                    
                    style="z-index: 7; white-space: nowrap;padding:10px 10px 10px 10px;"><i class="fa fa-thumbs-o-up"></i> 
                </div>
            </li>
            <!-- slide 1 end -->

            <!-- slide 2 start --> 
            <li data-transition="zoomin" data-slotamount="7"  data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed="2000"  data-thumb="assets/img/banner/banner-19.jpg"  data-rotate="0"  data-saveperformance="off"  data-title="Blue Sea" data-description="">

                <!-- MAIN IMAGE -->
                <img src="assets/img/banner/banner-19.jpg"  alt=""  data-bgposition="center center" data-kenburns="on" data-duration="10000" data-ease="Linear.easeNone" data-scalestart="100" data-scaleend="120" data-rotatestart="0" data-rotateend="0" data-offsetstart="0 -500" data-offsetend="0 500" data-bgparallax="10" class="rev-slidebg" data-no-retina>
                <!-- LAYERS -->

                <div class="tp-overlay"></div>

                <!-- LAYER NR. 1 -->
                <div class="tp-caption NotGeneric-Title tp-resizeme rs-parallaxlevel-0" 
                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
                    data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" 
                    data-fontsize="['70','70','70','45']"
                    data-lineheight="['70','70','70','50']"
                    data-width="none"
                    data-height="none"
                    data-whitespace="nowrap"
                    data-transform_idle="o:1;"
         
                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power2.easeInOut;" 
                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                    data-mask_in="x:0px;y:[100%];" 
                    data-mask_out="x:inherit;y:inherit;" 
                    data-start="1000" 
                    data-splitin="none" 
                    data-splitout="none" 
                    data-responsive_offset="on" 

                    
                    style="z-index: 5; white-space: nowrap;padding:10px 20px 10px 20px;">LOVE IT?
                </div>

                <!-- LAYER NR. 2 -->
                <div class="tp-caption rev-subheading white-text tp-resizeme rs-parallaxlevel-0" 
                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
                    data-y="['middle','middle','middle','middle']" data-voffset="['62','62','62','61']" 
                    data-width="none"
                    data-height="none"
                    data-whitespace="nowrap"
                    data-transform_idle="o:1;"
         
                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" 
                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                    data-mask_in="x:0px;y:[100%];s:inherit;e:inherit;" 
                    data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;" 
                    data-start="1500" 
                    data-splitin="none" 
                    data-splitout="none" 
                    data-responsive_offset="on" 

                    
                    style="z-index: 6; white-space: nowrap;">PURCHASE MATERIALIZE TODAY
                </div>

                <!-- LAYER NR. 3 -->
                <div class="tp-caption NotGeneric-Icon  tp-resizeme rs-parallaxlevel-0" 
                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" 
                    data-y="['middle','middle','middle','middle']" data-voffset="['-68','-68','-68','-68']" 
                    data-width="none"
                    data-height="none"
                    data-whitespace="nowrap"
                    data-transform_idle="o:1;"
                    data-style_hover="cursor:default;"
         
                     data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:1500;e:Power4.easeInOut;" 
                     data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                     data-mask_in="x:0px;y:[100%];" 
                     data-mask_out="x:inherit;y:inherit;" 
                    data-start="2000" 
                    data-splitin="none" 
                    data-splitout="none" 
                    data-responsive_offset="on" 

                    
                    style="z-index: 7; white-space: nowrap;padding:10px 10px 10px 10px;"><i class="fa fa-heart-o"></i>
                </div>
            </li>
            <!-- slide 2 end -->

          </ul>             
         </div><!-- end revolution slider -->
        </section><!-- end of slider wrapper -->
        <!-- jQuery -->
        <script type="application/javascript" defer src="../../assets/js/jquery-2.1.3.min.js"></script>
        
        <script type="application/javascript" defer src="../../assets/bootstrap/js/bootstrap.min.js"></script>
        
        <script type="application/javascript" defer src="../../assets/materialize/js/materialize.min.js"></script>
        
        <script type="application/javascript" defer src="../../assets/js/menuzord.js"></script>
        
        <script type="application/javascript" defer src="../../assets/js/bootstrap-tabcollapse.min.js"></script>
        
        <script type="application/javascript" defer src="../../assets/js/jquery.easing.min.js"></script>
        
        <script type="application/javascript" defer src="../../assets/js/jquery.sticky.min.js"></script>
        
        <script type="application/javascript" defer src="../../assets/js/smoothscroll.min.js"></script>
        
        <script type="application/javascript" defer src="../../assets/js/imagesloaded.js"></script>
        
        <script type="application/javascript" defer src="../../assets/js/jquery.stellar.min.js"></script>
        
        <script type="application/javascript" defer src="../../assets/js/jquery.inview.min.js"></script>
        
        <script type="application/javascript" defer src="../../assets/js/jquery.shuffle.min.js"></script>
        
        <script type="application/javascript" defer src="../../assets/owl.carousel/owl.carousel.min.js"></script>
        
        <script type="application/javascript" defer src="../../assets/flexSlider/jquery.flexslider-min.js"></script>
        
        <script type="application/javascript" defer src="../../assets/magnific-popup/jquery.magnific-popup.min.js"></script>
        
        <script type="application/javascript" defer src="../../assets/js/scripts.js"></script>

        <!-- RS5.0 Core JS Files -->
        <script type="application/javascript" defer src="../../assets/revolution/js/jquery.themepunch.tools.min.js"></script>
        <script type="application/javascript" defer src="../../assets/revolution/js/jquery.themepunch.revolution.min.js"></script>


        <!-- SLIDER REVOLUTION 5.0 EXTENSIONS  (Load Extensions only on Local File Systems! The following part can be removed on Server for On Demand Loading) -->
         
        <script type="application/javascript" defer src="../../assets/revolution/js/extensions/revolution.extension.video.min.js"></script>
        <script type="application/javascript" defer src="../../assets/revolution/js/extensions/revolution.extension.slideanims.min.js"></script>
        <script type="application/javascript" defer src="../../assets/revolution/js/extensions/revolution.extension.actions.min.js"></script>
        <script type="application/javascript" defer src="../../assets/revolution/js/extensions/revolution.extension.layeranimation.min.js"></script>
        <script type="application/javascript" defer src="../../assets/revolution/js/extensions/revolution.extension.kenburn.min.js"></script>
        <script type="application/javascript" defer src="../../assets/revolution/js/extensions/revolution.extension.navigation.min.js"></script>
        <script type="application/javascript" defer src="../../assets/revolution/js/extensions/revolution.extension.migration.min.js"></script>
        <script type="application/javascript" defer src="../../assets/revolution/js/extensions/revolution.extension.parallax.min.js"></script>

        <!-- <script  type="application/javascript" defer src="../../common/slider.js"></script> -->
  
 
  </div>
</template>

<script>

/* eslint-disable */
// import '@/assets/fonts/iconfont/material-icons.css';
// import '@/assets/fonts/font-awesome/css/font-awesome.min.css';
// import '@/assets/magnific-popup/magnific-popup.css';
import '@/assets/owl.carousel/assets/owl.carousel.css';
import '@/assets/owl.carousel/assets/owl.theme.default.min.css';
// import '@/assets/flexSlider/flexslider.css';
// import '@/assets/materialize/css/materialize.min.css';
// import '@/assets/bootstrap/css/bootstrap.min.css';
// import '@/assets/css/shortcodes/shortcodes.css';
import '@/assets/fonts/flaticon/flaticon.css';
// import '@/assets/slider/style.css';

// import '@/assets/revolution/css/settings.css';
// import '@/assets/revolution/css/layers.css';
// import '@/assets/revolution/css/navigation.css';

//import '@/common/slider.js';


export default {
    name: "HomeSlider"
}

</script>

<style>

</style>vue