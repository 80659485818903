import {store} from '../store'

export default (to, from, next) => {

  if (store.getters.token) {

    if (to.meta.scrollToTop) {
      window.scrollTo(0, 0)
    }
    next()

  } else if (store.getters.is_admin) {

    if (to.meta.scrollToTop) {
      window.scrollTo(0, 0)
    }
    next('/admin')

  } else {

    if (to.meta.scrollToTop) {
      window.scrollTo(0, 0)
    }
    next('/login')
  }
}
