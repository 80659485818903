<template>
  <div id="user-backend" ref="parentContainer">
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <title>Vestrade FX Investment</title>
    <!-- plugins:css -->
    <link href="../../assets-new/vendors/iconfonts/mdi/css/materialdesignicons.min.css"  rel="stylesheet" type="text/html" />
    <link href="../../assets-new/vendors/iconfonts/ionicons/dist/css/ionicons.css"  rel="stylesheet" type="text/html" />
    <link href="../../assets-new/vendors/iconfonts/flag-icon-css/css/flag-icon.min.css"  rel="stylesheet" type="text/html" />
    <link href="../../assets-new/vendors/css/vendor.bundle.base.css"  rel="stylesheet" type="text/html" />
    <link href="../../assets-new/vendors/css/vendor.bundle.addons.css"  rel="stylesheet" type="text/html" />
    <!-- endinject -->
    <!-- plugin css for this page -->
    <!-- End plugin css for this page -->
    <!-- inject:css -->
    <link href="../../assets-new/css/shared/style.css"  rel="stylesheet" type="text/html" />
    <!-- endinject -->
    <!-- Layout styles -->
    <link href="../../assets-new/css/demo_1/style.css"  rel="stylesheet" type="text/html" />
    <!-- End Layout styles -->
    <link rel="shortcut icon" href="../assets-new/images/favicon.ico" />
  </head>
  <body>
    <div class="container-scroller" >
      <!-- partial:partials/_navbar.html -->
      <nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
          <a class="navbar-brand brand-logo" href="/" style="margin-left:35px !important; margin-top:7px !important;">
            <!-- <img src="../assets-new/images/logo.svg" alt="logo" /> -->
            <img class="" src="assets/img/logo-white.png" alt="VesTradeFX"  width="40%"/>
             </a>
          <a class="navbar-brand brand-logo-mini" href="/" style="margin-top:7px !important; margin-right:12px !important; ">
            <img class="" src="./../assets/img/logo-vest.png" alt="VesTradeFX" /> </a>
        </div>
        <div class="navbar-menu-wrapper d-flex align-items-center">
          <ul class="navbar-nav">
            <li v-for="phone in phone_number" v-bind:key="phone.id" class="nav-item font-weight-semibold d-none d-lg-block">Call or SMS : {{ phone.phone_number }}</li>
          </ul>
          <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown d-none d-xl-inline-block user-dropdown">
              <a class="nav-link dropdown-toggle" id="UserDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                <div class="img-xs-nav">
                <img v-if="!profile_photo" class="img-xs rounded-circle img-xs-nav" src="./../assets/img/faces/marc.jpg" alt="Profile image"></div>
                <div class="img-xs-nav">
                <img v-if="profile_photo" class="img-xs rounded-circle img-xs-nav" :src="profile_photo" alt="Profile image"></div>
              </a>
              <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
                <div class="dropdown-header text-center" style="margin-top: -100px !important;">
                  <div class="img-md-nav" align="center">
                  <img v-if="!profile_photo" class="img-md rounded-circle img-md-nav" src="./../assets/img/faces/marc.jpg" alt="Profile image"></div>
                  <div class="img-md-nav" align="center">
                  <img v-if="profile_photo" class="img-md rounded-circle img-md-nav" :src="profile_photo" alt="Profile image"></div>
                  <p class="mb-1 mt-3 font-weight-semibold">{{ isUser.username }}</p>
                  <p class="font-weight-light text-muted mb-0">{{ isUser.email }}</p>
                </div>
                <a class="dropdown-item" @click="onLogout">Sign Out<i class="dropdown-item-icon ti-power-off"></i></a>
              </div>
            </li>
          </ul>
          <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas" @click="onClickedNav">
            <span class="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
      <!-- partial -->
      <div class="container-fluid page-body-wrapper" >
        <!-- partial:partials/_sidebar.html  -->
          <sidebar v-if="full_menu" isUser:isUser />

            <div v-if="isMenuOpen">
            <sidebarMobile class="sidebar-offcanvas" :class="{ active: isMenuOpen }" v-if="mobile_menu" isUser:isUser @valueEmitted="handleValueEmitted" /> 
            </div>

        <!-- partial -->
        <div v-bind:class="webMobileSwitch" class="main-panel" style="margin-top:-70px !important;" >
          <div class="content-wrapper">
            <router-view :key="$route.path"></router-view>
          </div>
          <!-- content-wrapper ends -->
          <!-- partial:partials/_footer.html -->
          <footer class="footer" style="margin-top:50px !important;">

            <div class="container-fluid clearfix">
                <nav class="float-left" style="color:#000;">
                  <ul>
                    <li>
                      <a href="/" target="_blank">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="/#/about-us" target="_blank">
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="/#/contact-us" target="_blank">
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </nav>
                <div class="copyright float-right">
                  &copy;
                  <span id="footer-date">2020</span>, All Rights Reserved <i class="material-icons">favorite</i>
                  <a href="/" target="_blank">Vestrade</a>
                </div>
        
              </div>
          </footer>
          <!-- partial -->
        </div>
        <!-- main-panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
    </body>
    <!-- container-scroller -->
    <!-- plugins:js -->
    <script type="application/javascript" defer src="../../assets-new/vendors/js/vendor.bundle.base.js"></script>
    <script type="application/javascript" defer src="../../assets-new/vendors/js/vendor.bundle.addons.js"></script>
    <!-- endinject -->
    <!-- Plugin js for this page-->
    <!-- End plugin js for this page-->
    <!-- inject:js -->
    <script type="application/javascript" defer src="../../assets-new/js/shared/off-canvas.js"></script>
    <script type="application/javascript" defer src="../../assets-new/js/shared/misc.js"></script>
    <!-- endinject -->
    <!-- Custom js for this page-->
    <script type="application/javascript" defer src="../../assets-new/js/demo_1/dashboard.js"></script>
    <!-- End custom js for this page-->
    <script type="application/javascript" defer src="../../assets-new/js/shared/jquery.cookie.js" ></script>

  </div>
</template>

<script>
// import '@/assets/css/material-dashboard.css';
// import '@/assets/css/material-dashboard.css?v=2.1.2';
import axios from "axios";
import API_URL  from './../common/config'

import '@/assets-new/vendors/iconfonts/mdi/css/materialdesignicons.min.css';
import '@/assets-new/vendors/iconfonts/ionicons/dist/css/ionicons.css';
import '@/assets-new/vendors/iconfonts/flag-icon-css/css/flag-icon.min.css';
import '@/assets-new/vendors/css/vendor.bundle.base.css';
import '@/assets-new/vendors/css/vendor.bundle.addons.css';
import '@/assets-new/css/shared/style.css';
import '@/assets-new/css/demo_1/style.css';

//import BackendFooter from '../components/Partials/backend-footer';
//import BackendUserNav from '../components/Partials/nav-user-backend'

import sidebar from '../components/Partials/new-user-nav-main';
import sidebarMobile from '../components/Partials/new-user-nav-mobile';

import $ from 'jquery'

export default {
    name: 'UserBackend',
  components: {
    sidebar, sidebarMobile
  },
  data() {
    return {
      isMenuOpen: false,
      full_menu: true,
      mobile_menu: false,
      phone_number: '',
      screenWidth: window.innerWidth,
      mini_logo: '',
      profile_photo: null,
      loading: false,
      links: {
        user_dashboard: {
          name: '',
          value: ''
        },
         user_crypto_trading: {
          name: '',
          value: ''
        },
         user_binary_trading: {
          name: '',
          value: ''
        },
         user_change_password: {
          name: '',
          value: ''
        },
         user_forex_trading: {
          name: '',
          value: ''
        },
         user_deposit_funds: {
          name: '',
          value: ''
        },
         user_edit_my_profile: {
          name: '',
          value: ''
        },
        user_personal_profile: {
          name: '',
          value: ''
        },
        user_refer_friend: {
          name: '',
          value: ''
        },
        user_upload_documents: {
          name: '',
          value: ''
        },
        user_withdraw_funds: {
          name: '',
          value: ''
        },
        logout: {
          name: ''
        }
      },
      username: '',
    }
  },
  mounted() {
    window.addEventListener("resize", this.updateScreenWidth);
    
  },
  created(){
    this.mini_logo = 'assets/img/logo-vest.png'
    //console.log('user1', this.$store.getters.is_admin)
    this.scrollToTop();

    //this.screenWidth(this.screenWidth)

    if (this.$store.getters.is_admin) {
      this.$router.push('/admin')
    }
    this.links.user_dashboard = {
      name: 'Dashboard',
      value: 'user_dashboard'
    }
     this.links.user_change_password = {
      name: 'Change Password',
      value: 'user_change_password'
    }
     this.links.user_crypto_trading = {
      name: 'Cryptocurrency Trading',
      value: 'user_crypto_trading'
    }
     this.links.user_forex_trading = {
      name: 'Forex Trading',
      value: 'user_forex_trading'
    }
     this.links.user_binary_trading = {
      name: 'Binary Trade',
      value: 'user_binary_trading'
    }
     this.links.user_deposit_funds = {
      name: 'Deposit Funds',
      value: 'user_deposit_funds'
    }
     this.links.user_edit_my_profile = {
      name: 'Edit Profile',
      value: 'user_edit_my_profile'
    }
   this.links.user_personal_profile = {
      name: 'Personal Profile',
      value: 'user_personal_profile'
    }
    this.links.user_refer_friend = {
        name: 'Refer a Friend',
        value: 'user_refer_friend'
    },
    this.links.user_upload_documents = {
        name: 'Upload Documents',
        value: 'user_upload_documents'
    },
    this.links.user_withdraw_funds = {
        name: 'Withdraw Funds',
        value: 'user_withdraw_funds'
      },
    this.links.logout = {
        name: 'Logout',
      }    
    this.addressDetails()
    this.getProfilePhoto()
  },
  updated: function() {
    //console.log('user2', this.$store.getters.is_admin)
    if (this.$store.getters.is_admin) {
       this.$router.push('/admin')
    }
    //console.log('username', this.$store.getters.user.username)
    this.username = this.$store.getters.user.username
  },
  computed: {
    isUser(){
      if (this.$store.getters.user.username) {
        console.log('== this.$store.getters.user ', this.$store.getters.user)
        return this.$store.getters.user //.username
      } else {
        return false
      }
    },
    webMobileSwitch() {
      if (this.screenWidth < 991) {
        return '';
      } else {
        return 'web-mobile-switch';
      }
    }
  },
  watch: {
    screenWidth(newWidth) {
      if (newWidth < 991) {
        // Do something when the screen width is less than 991px
        this.full_menu = false
        $('.sidebar-offcanvas').toggleClass('active')
        this.mobile_menu = true
      } else {
        this.full_menu = true
        $('.sidebar-offcanvas').toggleClass('')
        this.mobile_menu = false
      }
    }
  },
  methods: {
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    handleValueEmitted(isMenuOpen){
        this.scrollToTop();
      if (isMenuOpen) {
        this.isMenuOpen = false;
        this.mobile_menu= false;
      } else {
        this.isMenuOpen = true;
        this.mobile_menu= true
      }
      
    },
    onClickedNav() {
      console.log('Menu clicked');

      if (!this.isMenuOpen) {
        this.isMenuOpen = true;
        this.mobile_menu= true

      // $('[data-toggle="offcanvas"]').on("click", function (event) {
      //         event.preventDefault(); 
      //       $('.sidebar-offcanvas').toggleClass('active')
      //     });
      } else {
        this.isMenuOpen = false;
        this.mobile_menu= false;
        //$('.sidebar-offcanvas').toggleClass('')
      }
    },
    doubleClicked(){
      this.onClickedNav()
    },
    linkToPortfolio: function (name) {
      this.$router.push({ name: name });
    },
/*     onClickedNav() {
        if(this) {
            console.log(' menu cliked 2')

            if(this.mobile_menu == false){
              this.mobile_menu = true
            }

            $('[data-toggle="offcanvas"]').on("click", function (event) {
                event.preventDefault(); 
              $('.sidebar-offcanvas').toggleClass('active')
            });
          } 
      }, */
    addressDetails() {
      axios.get(API_URL + 'pages/phone_number/')
        .then(res => {
          this.phone_number = res.data
          // console.log('== this.phone_number ', this.phone_number)
        })
      },
      getProfilePhoto() {  
        var token = JSON.parse(localStorage.getItem('userData')).token

        axios.get(API_URL + 'profile/user-profile-photo/', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        .then(data => {
            //console.log(data);
            if(data.data.length > 0){
              this.profile_photo = data.data[0].profile_img
              //console.log('data.data.profile_img', data.data[0].profile_img);
            }
            this.loading = false
        })
        .catch((error) => {
            console.error('Error:', error);
            this.loading = false
        });
      },
      scrollToTop() {
        this.$nextTick(() => {
          const parentContainer = this.$refs.parentContainer;
          parentContainer.scrollTop = 0;
        });
      },
    onLogout () {
        this.$store.dispatch('logout')
        console.log('user logging', 'out - sidebar')       
      },
    
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenWidth);
  },
}
</script>

<style>
h1, h2, h3, h4, h5, h6 {
  color: purple !important;
}
.web-mobile-switch {
  margin-left:22% !important;
}

/* Hide the scrollbar in a container with overflow-y: scroll for all browsers */
.nav::-webkit-scrollbar,
.nav::-moz-scrollbar,
.nav::-o-scrollbar,
.nav::-ms-scrollbar {
  display: none;
}


/* Hide the scrollbar in a container with overflow-y: scroll for all browsers */
.sidebar::-webkit-scrollbar,
.sidebar::-moz-scrollbar,
.sidebar::-o-scrollbar,
.sidebar::-ms-scrollbar {
  display: none;
}

.page-title {
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.img-xs-nav {
  width: 50px !important;
  height: 50px !important;
}

.img-md-nav {
  width: 120px !important;
  height: 120px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

</style>