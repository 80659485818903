.<template>
  <div id="forex-chart" >
      <div class="clearfix"></div>
      <div class="">
       <br> <br>
       <h2>Live Market Statistics</h2>
    <div id="tradingview_c817d-wrapper" style="position: relative;box-sizing: content-box;width: 100%;height: 400px;margin: 0 auto !important;padding: 0 !important;font-family:Arial,sans-serif;"><div style="width: 100%;height: 400px;background: transparent;padding: 0 !important;"><iframe id="tradingview_c817d" src="https://s.tradingview.com/fxteam/widgetembed/?frameElementId=tradingview_c817d&amp;symbol=FX%3AEURUSD&amp;interval=5&amp;hidesidetoolbar=0&amp;symboledit=1&amp;saveimage=1&amp;toolbarbg=f1f3f6&amp;studies=%5B%5D&amp;hideideas=1&amp;theme=White&amp;style=8&amp;timezone=UTC&amp;showpopupbutton=1&amp;studies_overrides=%7B%7D&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;showpopupbutton=1&amp;utm_source=www.forexlive.com&amp;utm_medium=widget&amp;utm_campaign=chart&amp;utm_term=FX%3AEURUSD" style="width: 100%; height: 100%; margin: 0 !important; padding: 0 !important;" allowtransparency="true" scrolling="no" allowfullscreen="" frameborder="0"></iframe></div></div>
    
      </div>
      <br> <br>
      </div>
    
</template>

<script>
export default {
    name: 'ForexChart'
}
</script>

<style>

</style>