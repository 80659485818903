<template>
  <div class="admin-logn">
    <link href="assets/css/shortcodes/login.css" rel="stylesheet">
<!--page title start-->
        <section class="page-title page-title-center cover-4 padding-top-110 padding-bottom-60 overlay purple-5 fixed-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="white-text font-20 text-bold" style="margin-top:45px;">Admin Login / Signup</h4>
                    <!--     <ol class="breadcrumb">
                            <li><a href="#">Home</a></li>
                            <li class="active">Login/Signup</li>
                        </ol> -->
                    </div>
                </div>
            </div>
        </section>
        <!--page title end-->
     <section class="section-padding gray-bg">
            <div class="container">

                <div class="login-wrapper">
                  <div class="card-wrapper"></div>                  
                   <div class="card-wrapper">
                   <div class="logForm"  style="display:block;">
                    <h1 class="title">Admin Login</h1>
                    <form @submit.prevent="onAdminSignin">
                      <div class="input-container">
                        <input type="text" id="username" name="username" v-model="username" required="required" autocomplete="off" />
                        <label for="username">Username</label>
                        <div class="bar"></div>
                      </div>
                      <div class="input-container">
                        <input type="password" id="password" name="password" v-model="password" required="required" autocomplete="off" />
                        <label for="password">Password</label>
                        <div class="bar"></div>
                      </div>
                      <div class="button-container">
                        <button type="submit" class="btn btn-lg btn-block waves-effect waves-light">Login</button>
                      </div>
                      <div class="footer"><a href="#">Forgot your password?</a></div>
                    </form>
  
                    </div>
                    
                  </div>
                  
                </div>

            </div>
        </section>

  </div>
</template>

<script>
export default {
    name: "AdminLogin",
    data () {
        return {
            username: '',
            password: ''
        }
    },
    computed: {
      user () {
        return this.$store.getters.is_admin
      },
      token () {
        return this.$store.getters.token
      }
    },
    watch: {
      user (value) {
        if (value !== null && value !== undefined) {
          console.log('token 1=')
          this.$router.push('/admin')
        }
      },
      token (value) {
       
        console.log('token 2=', value)
    
      }
    },
    methods: {
        onToggle(){
            console.log('== is toggled ==')
            document.querySelector(".logForm").style.display= 'none';
            document.querySelector(".regForm").style.display= 'block';
            document.querySelector(".toggle").style.display= 'none';
            document.querySelector(".closes").style.display= 'block';
        },
        onClosed(){
             console.log('== is closes ==')
            document.querySelector(".logForm").style.display= 'block';
            document.querySelector(".regForm").style.display= 'none';
            document.querySelector(".closes").style.display= 'none';
            document.querySelector(".toggle").style.display= 'block';
        },
        onAdminSignin () {
        console.log({username: this.username, password: this.password})
        this.$store.dispatch('signAdminIn', {username: this.username, password: this.password})
      },
    },
}

</script>


<style scoped>

.regForm{
    margin-top: -60px !important;
}
.closes{


  background: #ed2553;
    width: 80px;
    height: 80px;
    margin-top: -80px !important;
    margin-left: 80%;

    color: antiquewhite;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  text-align: center;
  font-weight: bold;
    font-size: 30px;
    line-height: 80px;
  cursor: pointer;
}

</style>