<template>
  <div id="admin-change-password">
    <div class="row">
      <h1>Change Password</h1>
  </div>
  <!-- <BackendScripts /> -->
  </div>
</template>

<script>
// import BackendScripts from '../components/Partials/backend-scripts'

export default {
    name: 'AdminChangePassword',
    components: {
      // BackendScripts
    }
}
</script>

<style>

</style>