/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import { store } from './store'
// import ApiService from "./common/api.service";

//import 'jquery';
//import $ from 'jquery';
//import 'popper.js';
//import 'bootstrap';

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'

Vue.use(VueMaterial)

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexcharts', VueApexCharts)

import '@/assets/fonts/iconfont/material-icons.css';
import '@/assets/fonts/font-awesome/css/font-awesome.min.css';
import '@/assets/css/animated-headline.css';
import '@/assets/magnific-popup/magnific-popup.css';
import '@/assets/flexSlider/flexslider.css';
import '@/assets/materialize/css/materialize.min.css';
import '@/assets/bootstrap/css/bootstrap.min.css';
import '@/assets/css/shortcodes/shortcodes.css';
import '@/assets/style.css';
import '@/assets/css/skins/creative.css';

import '@/assets-new/vendors/iconfonts/mdi/css/materialdesignicons.min.css';
import '@/assets-new/vendors/iconfonts/ionicons/dist/css/ionicons.css';
import '@/assets-new/vendors/iconfonts/flag-icon-css/css/flag-icon.min.css';
import '@/assets-new/vendors/css/vendor.bundle.base.css';
import '@/assets-new/vendors/css/vendor.bundle.addons.css';
import '@/assets-new/css/shared/style.css';
import '@/assets-new/css/demo_1/style.css';



Vue.component('Backend', require('./layouts/backend.vue').default);
Vue.component('UserBackend', require('./layouts/userbackend.vue').default);

Vue.config.productionTip = false



new Vue({
  router, store,
  beforeCreate() { this.$store.commit('initialiseStore');}, 
  render: h => h(App),
  created () {
    if (localStorage.getItem('userData')) {
     
      this.$store.dispatch('autoSignIn', JSON.parse(localStorage.getItem('userData')))
      
      // this.$store.commit('setUser', JSON.parse(localStorage.getItem('userData')))
      // this.$store.commit('setToken', JSON.parse(localStorage.getItem('tokenData')))
      // this.$store.commit('setAdmin', JSON.parse(localStorage.getItem('isAdminData')))
      // console.log('setUser2', localStorage.getItem('userData'))
    }
  },
}).$mount('#app')
