import Vue from 'vue'
import Vuex from 'vuex'

import getUserData from './getUserData'
import user from './user'
import shared from './shared'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    agreedToPrivacy: false
    
  },
  mutations: {
    agreePrivacyPolicy(state) {
      localStorage.setItem('agreedToPrivacy', true);
      state.agreedToPrivacy = true;
      // console.log('setUser1', localStorage.getItem('userData'))
    },
    initialiseStore(state) {  
      if (localStorage.getItem('userData')) {
        state.agreedToPrivacy = true;
        this.commit('setUser', JSON.parse(localStorage.getItem('userData')))
        this.commit('setToken', JSON.parse(localStorage.getItem('tokenData')))
        this.commit('setAdmin', JSON.parse(localStorage.getItem('isAdminData')))
        // console.log('setUser2', localStorage.getItem('userData'))
      }
    }
  },
  modules: {
      //meetup: meetup,
      user: user,
      shared: shared,
      getUserData
    }
  })