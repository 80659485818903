<template>
  <div id="personal-profile">
    <div class="row">
      <h1>Personal Profile	</h1>
  </div>
    <div class="row">
            <div class="col-md-8">
              <div class="card" style="margin-top:50px !important;">
                <div class="" style="margin-top:30px !important;">
                  <h4 class="page-title">Edit Profile</h4>
                  <p class="card-category" style="margin-left: 30px;">Complete your profile</p>
                </div>
                <div class="card-body">
                  <form @submit.prevent="submitProfile" id="form1">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="">Company name (optional)</label>
                          <input type="text" name="company_name" v-model="company_name" class="form-control" >
                        </div>
                      </div>                     
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="">Fist Name</label>
                          <input type="text" name="first_name" v-model="first_name" required class="form-control">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="">Last Name</label>
                          <input type="text" name="last_name" v-model="last_name" required class="form-control">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="">Adress</label>
                          <input type="text" name="address" v-model="address" required class="form-control">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="">State / City</label>
                          <input type="text" name="city" v-model="city" required class="form-control">
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="">Country</label>
                          <input type="text" name="country" v-model="country" required class="form-control">
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="">Postal Code</label>
                          <input type="text" name="postal_code" v-model="postal_code" required class="form-control">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="">Phone number / GSM</label>
                          <input type="text" name="phone_number" v-model="phone_number" required class="form-control">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="">Whatsapp</label>
                          <input type="text" name="whatsapp" v-model="whatsapp" required class="form-control">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <div class="form-group">
                            <label class=""> About Me </label>
                            <textarea class="form-control"  name="about_me" v-model="about_me" rows="5"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button :disabled="loading_details" type="submit" class="btn btn-success">Submit</button>
                    <p v-if="loading_details">Please wait...</p>
                    <div class="clearfix"></div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <form @submit.prevent="submitProfilePic" id="form2">
              <div class="card card-profile" style="margin-top:30px !important;">
                <div class="card-avatar" style="margin-top:50px !important;">
                  <a href="javascript:;" v-if="!profile_photo">
                    <img class="img" src="../assets/img/faces/marc.jpg" />
                  </a>
                  <a href="javascript:;" v-if="profile_photo">
                    <img class="img" :src="profile_photo" />
                  </a>
                </div>
                <div class="card-body">
                  <h3 class="">Hi {{ username }} !</h3>
                  <h4 class="page-title"></h4>
                  <div class="card-description">
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="form-group">
                          <label for="input-84"><i class="fa fa-paperclip fa_custom fa-3x"></i> Add/Edit Profile Photo</label>            
                          <input ref="profile_img" v-on:change="handleFileUpload1()" required type="file" id="input-84" accept="image/*">
                        </div>
                      </div>
                    </div>
                  </div>
                  <button :disabled="loading" type="submit" class="btn btn-primary btn-round">Upload Photo</button>
                  <p v-if="loading">Please wait...</p>
                </div>
              </div>
              </form>
            </div>
          </div>
  <!-- <BackendScripts /> -->
  </div>
</template>

<script>
import axios from "axios";
import API_URL  from './../common/config'

export default {
    name: 'PersonalProfile',
    components: {
      // BackendScripts
    },
    data() {
      return {
        company_name: '',
        first_name: '',
        last_name: '',
        address: '',
        city: '',
        country: '',
        postal_code: '',
        about_me: '',
        phone_number: '',
        whatsapp: '',
        username: '',
        profile_img: null,
        profile_photo: null,
        loading: false,
        loading_details: false,
      }
    },
    created() {
      this.profileDetails()
      this.username = this.$store.getters.user.username
      this.getProfilePhoto()
    },
    methods: {
      submitProfile() {
        this.loading_details = true
        const formData = {
          company_name: this.company_name, 
          first_name: this.first_name, 
          last_name: this.last_name,
          address: this.address, 
          city: this.city, 
          country: this.country,
          postal_code: this.postal_code, 
          about_me: this.about_me, 
          whatsapp: this.whatsapp,
          phone_number: this.phone_number
        }
        //console.log('formData', formData)
        this.$store.dispatch('loadPersonalProfile', formData)
        .then(()=> {
          //console.log('== Form Submitted ', formData)
          setTimeout(() => {
            this.loading_details = false
          }, 2500);
        })
        .catch((error) => {
              console.error('Error:', error);
              setTimeout(() => {
                this.loading_details = false
              }, 2500);
          });
      },
       submitProfilePic() {
        if(!this.profile_img){
          alert('== Please Select a Photo')
          return
        }
          this.loading = true
          // Initialize the form data           
          let formData = new FormData();
          // Add the form data we need to submit
          formData.append('profile_img', this.profile_img);

          //console.log('formData1 ==', formData)

          var token = JSON.parse(localStorage.getItem('userData')).token

          axios.post(API_URL + 'profile/user-profile-photo/', formData, {
            headers: {
              'Authorization': `Token ${token}`
            }
          })
          .then(() => {
              //console.log(data);
              alert('Your Profile Photo Uploading was Successful!')
              this.profile_img = null
              this.getProfilePhoto()
              this.loading = false
          })
          .catch((error) => {
              console.error('Error:', error);
              this.loading = false
              this.profile_img = null
          });
      },
      handleFileUpload1(){
        this.profile_img = this.$refs.profile_img.files[0];
        //console.log('this.profile_img ==', this.profile_img)
      },
      getProfilePhoto() {  
        var token = JSON.parse(localStorage.getItem('userData')).token

        axios.get(API_URL + 'profile/user-profile-photo/', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        .then(data => {
            //console.log(data);
            if(data.data.length > 0){
              this.profile_photo = data.data[0].profile_img
              //console.log('data.data.profile_img', data.data[0].profile_img);
            }
            this.loading = false
        })
        .catch((error) => {
            console.error('Error:', error);
            this.loading = false
        });
      },
      profileDetails() {
        var token = JSON.parse(localStorage.getItem('userData')).token
        var headers = {'Authorization': `Token ${token}`}

        axios.get(API_URL + 'profile/user-details/', {headers: headers})
        .then(res => {
            //console.log('profileDetails data 1', res.data)
            this.company_name =  res.data[0].company_name  
            this.first_name =  res.data[0].first_name  
            this.last_name =  res.data[0].last_name 
            this.address =  res.data[0].address  
            this.city =  res.data[0].city  
            this.country =  res.data[0].country 
            this.postal_code =  res.data[0].postal_code  
            this.about_me =  res.data[0].about_me  
            this.whatsapp =  res.data[0].whatsapp 
            this.phone_number =  res.data[0].phone_number
            //console.log('profileDetails data 2', res.data.company_name )
        })
      }
    },
}
</script>

<style scoped>
div {
  font-size: 16px !important;
}
 span {
    font-size: 16px !important;
 }
 p {
    font-size: 16px !important;
 }
 label {
  font-size: 14px !important;
}
th {
  font-size: 18px !important;
}
tbody {
  font-size: 14px;
}
 button {
  font-size: 18px !important;
  padding: 10px 35px !important;
  width: 200px;
  justify-items: center;
  margin-top: 30px;
}

</style>