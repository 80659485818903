<template>
  <div id="app">
    <body id="top" class="has-header-search">
     <!-- <Backend /> -->
    <router-view></router-view>
    <!-- <Frontend /> -->
  </body>
  </div>
</template>

<script>
// import Home from './components/Home.vue'
// import UserBackend from './../layouts/userbackend';
// import Backend from './../layouts/backend';

export default {
  name: 'App',
  components: {
    // Backend, Frontend
  }
}
</script>

<style>


#app {
  margin-top: 0px;
}
</style>
