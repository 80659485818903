<template>
  <div id="admin-dashboard">
      <h1>Admin Dashboard</h1>
  </div>
</template>

<script>
export default {
    name: "AdminDashboard"
}
</script>

<style>

</style>