import { render, staticRenderFns } from "./withdraw-funds.vue?vue&type=template&id=c08c45b2&scoped=true&"
import script from "./withdraw-funds.vue?vue&type=script&lang=js&"
export * from "./withdraw-funds.vue?vue&type=script&lang=js&"
import style0 from "./withdraw-funds.vue?vue&type=style&index=0&id=c08c45b2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c08c45b2",
  null
  
)

export default component.exports