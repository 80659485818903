<template>
  <div class="navbar-section" >    
        <header id="header" class="tt-nav transparent-header"><!-- nav-border-bottom -->
 
            <div class="header-sticky light-header">

                <div class="container">

                    <div class="search-wrapper">
                        <div class="search-trigger light pull-right">
                            <div class='search-btn'></div>
                            <i class="material-icons">&#xE8B6;</i>
                        </div>

                        <!-- Modal Search Form -->
                        <i class="search-close material-icons">&#xE5CD;</i>
                        <div class="search-form-wrapper">
                            <form action="#" class="white-form">
                                <div class="input-field">
                                    <input type="text" name="search" id="search">
                                    <label for="search" class="">Search Here...</label>
                                </div>
                                <button class="btn pink search-button waves-effect waves-light" type="submit"><i class="material-icons">&#xE8B6;</i></button>
                                
                            </form>
                        </div>
                    </div><!-- /.search-wrapper -->


                    <div id="materialize-menu" class="menuzord" @click="frontMenu()">

                        <!--logo start-->
                        <a href="/" class="logo-brand">
                            <img class="logo-dark" src="assets/img/logo.png" alt=""/>
                            <img class="logo-light" src="assets/img/logo-white.png" alt=""/>
                        </a>
                        <!--logo end-->

                        <!-- menu start-->
                        <ul class="menuzord-menu pull-right light" >
                            <li id="menu-clicked" class="active"><a v-on:click="linkToPortfolio(links.home.value)">{{ links.home.name }}</a></li>
                            <li id="menu-clicked"><a v-on:click="linkToPortfolio(links.about_us.value)">{{ links.about_us.name }}</a>
                              <!-- <ul class="dropdown">
                                    <li><a v-on:click="linkToPortfolio(links.about_us.value)">{{ links.about_us.name }}</a></li>
                                    <li><a href="creative-about-me.html">About Me</a></li>
                                </ul> -->
                            </li>                            
                            <li><a >Trading</a>
                                <ul class="dropdown">                                    
                                    <li id="menu-clicked"><a v-on:click="linkToPortfolio(links.crypto_trading.value)">{{ links.crypto_trading.name }}</a></li>
                                    <li id="menu-clicked"><a v-on:click="linkToPortfolio(links.forex_trading.value)">{{ links.forex_trading.name }}</a></li>
                                    <li id="menu-clicked"><a v-on:click="linkToPortfolio(links.binary_trading.value)">{{ links.binary_trading.name }}</a></li>          
                                </ul>
                            </li>
                            <li id="menu-clicked"><a v-on:click="linkToPortfolio(links.regulation_page.value)">{{ links.regulation_page.name }}</a></li>
                            <li id="menu-clicked"><a v-on:click="linkToPortfolio(links.guide_page.value)">{{ links.guide_page.name }}</a></li>
                            <li v-if="!LoginNavGuard && !LoginNavGuardAdmin"><a v-on:click="linkToPortfolio(links.login.value)">{{ links.login.name }}</a>
                                <ul class="dropdown">                                   
                                    <li id="menu-clicked"><router-link :to="'/user'"><b>User Login / Signup</b></router-link></li>
                                    <li id="menu-clicked"><router-link :to="'/admin-login'"><b>Admin Login</b></router-link></li>                                           
                                </ul>
                            </li>
                            <li v-if="LoginNavGuard || LoginNavGuardAdmin "><a @click="onLogout">{{ links.logout.name }}</a>
                            <ul class="dropdown">
                            <li id="menu-clicked" v-if="LoginNavGuard"><router-link :to="'/user'"><b>User Dashboard</b></router-link></li>
                            <li id="menu-clicked" v-if="LoginNavGuardAdmin"><router-link :to="'/admin'"><b>Admin Dashboard</b></router-link></li>
                            </ul>
                            </li>
                            <li id="menu-clicked"><a v-on:click="linkToPortfolio(links.contact_us.value)">{{ links.contact_us.name }}</a></li>
                        </ul>
                        <!-- menu end-->

                    </div>
                </div>
            </div>
        </header>
  </div>
</template>

<script>
import $ from 'jquery';

//import store from 

export default {
  name: 'Navbar',
  data () {
    return {
      links: {
        home: {
          name: '',
          value: ''
        },
         about_us: {
          name: '',
          value: ''
        },
         crypto_trading: {
          name: '',
          value: ''
        },
         forex_trading: {
          name: '',
          value: ''
        },
        binary_trading: {
          name: '',
          value: ''
        },
         guide_page: {
          name: '',
          value: ''
        },
         regulation_page: {
          name: '',
          value: ''
        },        
         login: {
          name: '',
          value: ''
        },        
         admin_login: {
          name: '',
          value: ''
        },
        contact_us: {
          name: '',
          value: ''
        },
        logout: {
          name: ''
        }
      },
      LoginNavGuard: false,
      LoginNavGuardAdmin: false
    }
  },
  computed: {
      user () {
        return true
      }
    },
    watch: {
 
      user (value) {
        console.log('= checkLogin 2 =')
        if (value !== null && value !== undefined) {
          console.log('= isLogin =')
          //this.$router.push('/user')

        }
      }
    },
  created () {
    this.links.home = {
      name: 'Home',
      value: 'home'
    }
     this.links.about_us = {
      name: 'About Us',
      value: 'about_us'
    }
     this.links.crypto_trading = {
      name: 'Cryptocurrency Trading',
      value: 'crypto_trading'
    }
     this.links.forex_trading = {
      name: 'Forex Trading',
      value: 'forex_trading'
    }
    this.links.binary_trading = {
      name: 'Binary Trade',
      value: 'binary_trading'
    }
     this.links.guide_page = {
      name: 'Guide',
      value: 'guide_page'
    }
     this.links.regulation_page = {
      name: 'Regulation',
      value: 'regulation_page'
    }
   this.links.contact_us = {
      name: 'Contact Us',
      value: 'contact_us'
    }
  this.links.login = {
      name: 'Login/Signup',
      value: 'login'
    }
  this.links.admin_login = {
      name: 'Admin Login',
      value: 'admin_login'
    },
  this.links.logout = {
      name: 'Logout',
    }
  this.isLoginMenu()
  },
  methods: {
    linkToPortfolio: function (name) {
      this.$router.push({ name: name })
      //window.location.href('/' + name)
      this.$emit('homeScrolToTop', true);
    },
    isLoginMenu () {
      console.log('= checkLogin 1 =', localStorage.getItem('userData'))
        if (localStorage.getItem('userData')) {
          if(JSON.parse(localStorage.getItem('userData')).is_admin) {
            this.LoginNavGuardAdmin = true
            console.log('= LoginNavGuardAdmin 1=', this.LoginNavGuardAdmin)
            return this.LoginNavGuard
          } else {
          this.LoginNavGuard = true
          console.log('= LoginNavGuard 1=', this.LoginNavGuard)
            return this.LoginNavGuard
          }
        }else {
        console.log('= LoginNavGuard 2=', this.LoginNavGuard)
        return this.LoginNavGuard
        }
    },
    onLogout () {
        this.$store.dispatch('logout')
        console.log('== home logging out')       
      },

    frontMenu() {
      this
      if(this){
        console.log('== Toggle Menu ==')//menu-clicked
            $(".menuzord-menu #menu-clicked").click(function(event) {
              event.preventDefault();
              var w = $(window).width();
              console.log(' Toggle Menu 2 ==', w)   
              if (w < 768) {
                if($(".showhide").is(":visible")){
                    $(".showhide").trigger("click");
                }
              }         
            });
      }
      
      //$("#menu-toggled").toggleClass("toggled");
    }
  }
}
</script>

<style scoped lang="css">
/* @import '../../assets/SCSS/mixin.scss';
@import '../../assets/SCSS/colors.scss';
@import '../../assets/SCSS/fonts.scss';
@import '../../assets/SCSS/variables.scss'; */

.navbar-section {
  display: flex;

}
</style>
