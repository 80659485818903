<template>
  <div id="refer-friend">
    <div class="row">
      <h1>Refer a Friend</h1>
  </div>
  
  <div class="row">
      <div class="col-lg-12 col-sm-12">
        <div class="card card-chart">
          <div class="" style="margin-top:15px !important;">
            <h4 class="page-title">Copy your Referral Link below</h4>
          </div>
            <div class="card-body">
              <h3><b>Referral Link:</b><input type="text" v-model="referralCode" id="referral-link" ></h3> 
              <button @click="Copy()" class="btn btn-primary">Copy Referral Link</button>               
            </div>
        </div>
      </div><!-- End Column    -->
  </div>

  <div class="row">
     <div class="col-lg-12 col-md-12">       
        <div class="card">
          <div class="" style="margin-top:15px !important;">
            <h4 class="page-title">Referrals Bonus</h4>
            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
          </div>
          
          <div class="card-body table-responsive">
            <br>
            <table class="table table-hover">
              <thead class="text-primary">
                <th>Referred User</th>
                <th>Bonus Amount</th>
                <th>Start Investment date</th>
                 <th>Signup Date</th>
              </thead>
              <tbody>
                <tr v-for="item in referralbonus" v-bind:key="item.id">
                  <td>{{ item.referred_user }}</td>
                  <td>$ {{ item.referral_bonus }}</td>
                  <td v-if="item.date_start_investment">{{ item.date_start_investment | formatdate }}</td>
                  <td v-if="!item.date_start_investment">Not yet.</td>
                  <td>{{ item.date_signup | formatdate }}</td>
                </tr>                
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- End Column -->
  </div>

  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import API_URL  from './../common/config';
import REFERRAL_API_URL from './../common/url-config';
import Vue from "vue";
import moment from "moment";

Vue.filter("formatdate", function(value) {
 if (value) {
 return moment(String(value)).format("MM-DD-YYYY");//"MM/DD/YYYY hh:mm"
 }
});

export default {
    name: 'ReferFriend',
    components: {
      // BackendScripts
    },
    data() {
      return {
        referralCode: '',
        referralbonus: []
      }
    },
    created() {
      this. getReferralbonus()
      this.getReferralCode()
    },
    methods: {
      getReferralbonus() {
        this.$store.dispatch('loadReferralBonus').then(data => {
          this.referralbonus = data
        })
      },
      getReferralCode() {
        var token = JSON.parse(localStorage.getItem('userData')).token
        var headers = {'Authorization': `Token ${token}`}

        axios.get(API_URL + 'referral/referral-code/', {headers: headers})
        .then(res => {
          this.referralCode = REFERRAL_API_URL + res.data[0].referral_code
        })
      },
      Copy() {

            var Url = document.getElementById("referral-link");
			      Url.value = this.referralCode
            Url.focus();
            Url.select();  
            document.execCommand("Copy");
            alert('Referral Linked Copied')
      }
    },
}
</script>

<style scoped>

button {
  font-size: 18px !important;
  padding: 10px 35px !important;
  width: 250px;
  justify-items: center;
  font-weight: bold;
}

</style>