<template>
    <!-- <div> -->
        <nav  class="nav-background-color sidebar sidebar-offcanvas" :class="{ active: isMenuOpen }" id="sidebar" data-toggle="offcanvas" @click="onClickedNav()" >
          <!-- class="nav-background-color sidebar sidebar-offcanvas" id="sidebar" -->
          <!-- style="margin-left:0px !important; margin-top:40px !important; overflow-y: scroll; height: 150% !important;" -->
          <ul class="nav-background-color nav " style="margin-left:-15px !important;" >
            <!-- style="overflow-y: scroll; height: 150% !important; margin-left:-30px !important;"  -->
            <li class="nav-item nav-profile">
              <a href="#" class="nav-link">
                <div class="profile-image">
                  <img v-if="!profile_photo" class="img-xs rounded-circle" src="./../../assets/img/faces/marc.jpg" alt="profile image">
                  <img v-if="profile_photo" class="img-xs rounded-circle" :src="profile_photo" alt="profile image">
                  <div class="dot-indicator bg-success"></div>
                </div>
                <div class="text-wrapper">
                  <p><span class="profile-name link-color">{{ isUser.username }}</span><br style="margin-top: -10px;">
                  <span class="designation link-color" style="font-size:11px !important;">{{ isUser.email }}</span></p>
                </div>
              </a>
            </li>
            <li class="nav-item nav-category">Main Menu</li>
            <li class="nav-item active">
                <a class="nav-link"  v-on:click="linkToPortfolio(links.user_dashboard.value)">
                  <i class="material-icons">dashboard</i>
                  <p class="link-color">{{ links.user_dashboard.name }}</p>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" v-on:click="linkToPortfolio(links.user_crypto_trading.value)">
                  <i class="material-icons">language</i>
                  <p class="link-color">{{ links.user_crypto_trading.name }}</p>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" v-on:click="linkToPortfolio(links.user_forex_trading.value)">
                  <i class="material-icons">language</i>
                  <p class="link-color">{{ links.user_forex_trading.name }}</p>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" v-on:click="linkToPortfolio(links.user_binary_trading.value)">
                  <i class="material-icons">language</i>
                  <p class="link-color">{{ links.user_binary_trading.name }}</p>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link"  v-on:click="linkToPortfolio(links.user_deposit_funds.value)">
                  <i class="material-icons">library_books</i>
                  <p class="link-color">{{ links.user_deposit_funds.name }}</p>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link"  v-on:click="linkToPortfolio(links.user_withdraw_funds.value)">
                  <i class="material-icons">language</i>
                  <p class="link-color">{{ links.user_withdraw_funds.name }}</p>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link"  v-on:click="linkToPortfolio(links.user_personal_profile.value)">
                  <i class="material-icons">person</i>
                  <p class="link-color">{{ links.user_personal_profile.name }}</p>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link"  v-on:click="linkToPortfolio(links.user_upload_documents.value)">
                  <i class="material-icons">library_books</i>
                  <p class="link-color">{{ links.user_upload_documents.name }}</p>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link"  v-on:click="linkToPortfolio(links.user_change_password.value)">
                  <i class="material-icons">insert_link</i>
                  <p class="link-color">{{ links.user_change_password.name }}</p>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link"  v-on:click="linkToPortfolio(links.user_refer_friend.value)">
                  <i class="material-icons">unarchive</i>
                  <p class="link-color">{{ links.user_refer_friend.name }}</p>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link"  @click="onLogout">
                  <i class="material-icons">eject</i>
                  <p class="link-color">{{ links.logout.name }}</p>
                </a>
              </li>
          </ul>
        </nav>
    <!-- </div> -->
</template>
<script>
import axios from "axios";
import API_URL  from '../../common/config';
import $ from 'jquery'

import '@/assets-new/vendors/iconfonts/mdi/css/materialdesignicons.min.css';
import '@/assets-new/vendors/iconfonts/ionicons/dist/css/ionicons.css';
import '@/assets-new/vendors/iconfonts/flag-icon-css/css/flag-icon.min.css';
import '@/assets-new/vendors/css/vendor.bundle.base.css';
import '@/assets-new/vendors/css/vendor.bundle.addons.css';
import '@/assets-new/css/shared/style.css';
import '@/assets-new/css/demo_1/style.css';

export default {
    name: 'sidebar',
  data() {
    return {
      loading: false,
      isMenuOpen: true,
      profile_photo: null,
      links: {
        user_dashboard: {
          name: '',
          value: ''
        },
         user_crypto_trading: {
          name: '',
          value: ''
        },
         user_binary_trading: {
          name: '',
          value: ''
        },
         user_change_password: {
          name: '',
          value: ''
        },
         user_forex_trading: {
          name: '',
          value: ''
        },
         user_deposit_funds: {
          name: '',
          value: ''
        },
         user_edit_my_profile: {
          name: '',
          value: ''
        },
        user_personal_profile: {
          name: '',
          value: ''
        },
        user_refer_friend: {
          name: '',
          value: ''
        },
        user_upload_documents: {
          name: '',
          value: ''
        },
        user_withdraw_funds: {
          name: '',
          value: ''
        },
        logout: {
          name: ''
        }
      },
      username: ''
    }
  },
  //props: ['isUser'],
  created(){
    console.log('user1', this.$store.getters.is_admin)

    if (this.$store.getters.is_admin) {
       this.$router.push('/admin')
    }
    this.links.user_dashboard = {
      name: 'Dashboard',
      value: 'user_dashboard'
    }
     this.links.user_change_password = {
      name: 'Change Password',
      value: 'user_change_password'
    }
     this.links.user_crypto_trading = {
      name: 'Cryptocurrency Trading',
      value: 'user_crypto_trading'
    }
     this.links.user_forex_trading = {
      name: 'Forex Trading',
      value: 'user_forex_trading'
    }
     this.links.user_binary_trading = {
      name: 'Binary Trade',
      value: 'user_binary_trading'
    }
     this.links.user_deposit_funds = {
      name: 'Deposit Funds',
      value: 'user_deposit_funds'
    }
     this.links.user_edit_my_profile = {
      name: 'Edit Profile',
      value: 'user_edit_my_profile'
    }
   this.links.user_personal_profile = {
      name: 'Personal Profile',
      value: 'user_personal_profile'
    }
  this.links.user_refer_friend = {
      name: 'Refer a Friend',
      value: 'user_refer_friend'
  },
  this.links.user_upload_documents = {
      name: 'Upload Documents',
      value: 'user_upload_documents'
  },
  this.links.user_withdraw_funds = {
      name: 'Withdraw Funds',
      value: 'user_withdraw_funds'
    },
  this.links.logout = {
      name: 'Logout',
    }
    this.getProfilePhoto()
  },
  updated: function() {
    //console.log('user2', this.$store.getters.is_admin)
    if (this.$store.getters.is_admin) {
       this.$router.push('/admin')
    }
    //console.log('username', this.$store.getters.user.username)
    this.username = this.$store.getters.user.username
  },
  computed: {
    isUser(){
      if (this.$store.getters.user.username) {
        return this.$store.getters.user
      } else {
        return false
      }
    }    
  },
  methods: {
    linkToPortfolio: function (name) {
      this.$router.push({ name: name });
      console.log(' linkToPortfolio 1 ')

      this.$emit('valueEmitted', true);
      //window.location.assign('user/' + name)
      //this.onClickedNav()
    },
    getProfilePhoto() {  
        var token = JSON.parse(localStorage.getItem('userData')).token

        axios.get(API_URL + 'profile/user-profile-photo/', {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
        .then(data => {
            //console.log(data);
            if(data.data.length > 0){
              this.profile_photo = data.data[0].profile_img
              //console.log('data.data.profile_img', data.data[0].profile_img);
            }
            this.loading = false
        })
        .catch((error) => {
            console.error('Error:', error);
            this.loading = false
        });
      },
    onClickedNav() {
        if(this) {
            console.log(' menu inner cliked 11')
            this.isMenuOpen= false
            $('[data-toggle="offcanvas"]').on("click", function (event) {
                event.preventDefault(); 
               //$('.sidebar-offcanvas').removeClass('active');
               //$('.sidebar-offcanvas').toggleClass('active')
               $('.sidebar-offcanvas').toggleClass('')
            });
          } else {
            console.log(' off menu cliked ')
          }

      },
    onLogout () {
        this.$store.dispatch('logout')
        console.log('user logging', 'out - sidebar')       
      },
    
  },

}
</script>
<style>
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - 33px);
    width: 320px !important;
    background-color: #3c58f1 !important;
    top: 33px;
    bottom: 0;
    overflow: auto;
    right: -270px !important;
    transition: all 0.25s ease-out; }
    .sidebar-offcanvas.active {
      left: -270px !important;
      
      /* right: 0 !important; */
    } }
.link-color {
  color: #fff !important;
}
.nav-background-color {
  background-color: #3c58f1 !important;
}
</style>