<template>
  <div id="crypto-trading">
    <div class="row">
      <h1>Binary Trade</h1>

            <div class="col-md-12">
              <br><br>
              <h2>Live Market Statistics</h2>
              <div id="tradingview_49156-wrapper" style="position: relative;box-sizing: content-box;width: 100%;height: 450px !important;margin: 0 auto !important;padding: 0 !important;font-family:Arial,sans-serif;"><div style="width: 100%;height: 100%;background: transparent;padding: 0 !important;"><iframe id="tradingview_49156" src="https://s.tradingview.com/widgetembed/?frameElementId=tradingview_49156&amp;symbol=NASDAQ%3AAAPL&amp;interval=5&amp;hidesidetoolbar=0&amp;symboledit=1&amp;saveimage=1&amp;toolbarbg=f1f3f6&amp;details=1&amp;hotlist=1&amp;studies=ACCD%40tv-basicstudies%1FstudyADR%40tv-basicstudies%1FAROON%40tv-basicstudies%1FATR%40tv-basicstudies&amp;hideideas=1&amp;theme=White&amp;style=1&amp;timezone=Etc%2FUTC&amp;withdateranges=1&amp;studies_overrides=%7B%7D&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;locale=en&amp;utm_source=www.binaryoptions.net&amp;utm_medium=widget&amp;utm_campaign=chart&amp;utm_term=NASDAQ%3AAAPL" style="width: 100%; height: 100%; margin: 0 !important; padding: 0 !important;" allowtransparency="true" scrolling="no" allowfullscreen="" frameborder="0"></iframe></div></div>
            </div>


            <div class="col-md-12" >
              <br><br>
              <div class="card">
               <!--  <div class="" >
                  <h4 class="card-title ">Simple Table</h4>
                  <p class="card-category"> Here is a subtitle for this table</p>
                </div> -->
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table" >
                      <thead class=" text-primary">
                        <th>
                          Trade
                        </th>
                        <th>
                          100%
                        </th>
                        <th>
                          Profit
                        </th>
                        <th>
                          Free Margin
                        </th>
                      </thead>
                      <tbody>
                        <tr v-for="item in tradingContents" v-bind:key="item.id">
                          <td>
                            {{ item.trading_package }} Package
                          </td>
                          <td  class="text-primary">
                            {{ item.percentage }} %
                          </td>
                          <td>
                           $  {{ item.profit }}
                          </td>
                          <td>
                            $  {{ item.free_margin }}
                          </td>
                        </tr>              
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- row column -->
            <div class="col-md-12">
              <div class="card">
               <!--  <div class="" >
                  <h4 class="card-title ">Simple Table</h4>
                  <p class="card-category"> Here is a subtitle for this table</p>
                </div> -->
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table" >
                      <thead class=" text-primary">
                        <th>
                          Credit
                        </th>
                        <th>
                          Balance
                        </th>
                        <th>
                          Trade ID
                        </th> 
                        <th>
                          Start Date
                        </th>              
                      </thead>
                      <tbody>
                        <tr v-for="item in tradingContents" v-bind:key="item.id">                     
                          <td>
                            $  {{ item.credit }}
                          </td>
                          <td>
                            $  {{ item.balance }}
                          </td>
                          <td>
                            {{ item.trade_id }}
                          </td>
                          <td>
                            {{ item.start_date | formatdate }}
                          </td>
                        </tr>
                        <!-- <tr>
                          <td colspan="3">
                          
                          </td>
                          </tr>                       -->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12" >
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-6">
                   <div align='center'><button @click.prevent="sellTrade" class="alert alert-success"><b>SELL</b></button> </div>
                </div>
                  <div class="col-md-6 col-sm-6 col-xs-6">
                     <div align='center'><button @click.prevent="buyTrade" class="alert alert-primary"><b>BUY</b></button> </div>
                  </div>
                  <!-- Start Submit Buy or Sell Options -->
                  <div class="col-md-12 col-sm-12 col-xs-12" align="center" v-if="isModel" >
                    <div style="border:1px solid #000; border-radius: 25px; width:80% !important; margin-top:20px;"> 
                       <h3 style="margin-top:20px;"><b>Do you want to {{ selectedText }}?</b></h3>

                        <div class="form-group" align="center">
                          <lable for="select-input">Select Yes or No</lable>
                          <select class="form-control" id="select-input" v-model="selectedOption" align="center">
                            <option selected align="center" :value="''">Select options below</option>
                            <option :value="'Yes'" align="center">Yes</option>
                            <option :value="'No'" align="center">No</option>
                          </select>
                        </div>

                        <div class="form-group">
                          <label for="select-input">Enter Amount ($) numbers only</label>
                          <input type="text" v-model="amount" :placeholder="`   Enter Amount to ` + selectedText" class="form-control" @input="onInput" required style="background-color: #fff !important;" />
                        </div>

                        <div class="form-group">
                          <button @click="submitTrade()" :disabled="disableButton" class="alert alert-success">Confirm</button>
                          <button @click="cancelTrade()" class="alert alert-danger">Cancel</button>
                          <p v-if="loading">Please wait...</p>
                        </div>
                    </div>
                  </div>
                  <!-- End Submit Buy or Sell Options -->
              </div>
            </div>
  </div>
  <!-- <BackendScripts /> -->
  </div>
</template>

<script>
/* eslint-disable */

import Vue from "vue";
import moment from "moment";
import axios from "axios";
import API_URL  from './../common/config'

Vue.filter("formatdate", function(value) {
 if (value) {
 return moment(String(value)).format("MM-DD-YYYY");//"MM/DD/YYYY hh:mm"
 }
});

export default {
    name: 'BinaryTrading',
    components: {
      // BackendScripts
    },
    data () {
      return {
        tradingContents: [],
        showModal: false,
        selectedOption: '',
        selectedText: '',
        isModel: false,
        TradingDuration: [7, 14, 21, 30],
        disableButton: false,
        loading: false,
        amount: '',
      }
      },
    created() {
      this.getBinaryContents()
    },
    methods: {
      onInput() {
        let sanitizedInput = this.amount.replace(/[^0-9]/g, "");
        this.amount = sanitizedInput;
      },
      getBinaryContents () {
        this.$store.dispatch('loadBinaryTrading').then(
          data => {          
            this.tradingContents = data
          })
      },
      sellTrade(){
        this.isModel = true
        this.selectedText = "Sell"
        console.log('== Sell ', this.selectedText)
      },
      buyTrade(){
        this.isModel = true
        this.selectedText = "Buy"
         console.log('== Buy ', this.selectedText)
      },
      submitTrade() {
        //console.log('== selectedOption', this.selectedOption);
        if(!this.amount){
          alert('== Please Add Amount ==')
          return
        }
        if(this.selectedOption == 'Yes'){
          this.loading = true
          this.disableButton = true
          console.log('== buy_or_sell ', this.selectedText)
          const postData = {buy_or_sell:this.selectedText, trading_type:'Binary', amount: this.amount }

          var token = JSON.parse(localStorage.getItem('userData')).token
          var headers = {'Authorization': `Token ${token}`}

          axios.post(API_URL + 'deposit-withdraw/buy-or-sell/', postData, {headers:headers})
          .then(() => {
            if(this.selectedText == "Buy"){
              alert('You have Successfully Buy!')
            } else {
              alert('You have Successfully Sold!')
            }
            this.disableButton = false
            this.isModel = false
            this.loading = false
          })
        } else {
          this.isModel = false
          this.disableButton = false
          this.loading = false
        }
      },
      cancelTrade(){
        this.isModel = false
        this.disableButton = false
        this.loading = false
      }
    },
  }
    

</script>

<style>
th {
  font-size: 18px !important;
}
tbody {
  font-size: 14px;
}

button {
  padding: 10px 35px !important;
  font-weight: bold;
  width: 120px;
}

</style>