<template>
  <div>
       <footer class="footer footer-one">
            <div class="primary-footer brand-bg">
                <div class="container">
                    <a href="#top" class="page-scroll btn-floating btn-large pink back-top waves-effect waves-light" data-section="#top">
                      <i class="material-icons">&#xE316;</i>
                    </a>

                    <div class="row">
                        <div class="col-md-4 widget clearfix">
                            <p><img src="assets/img/card-app.png" alt="card app copy" style="float: right;" width="90%"></p>
                        </div><!-- /.col-md-3 -->

                        <div class="col-md-4 widget">
                            <h2 class="white-text">Contact Us</h2>
                            <!--       address: '',
      email_address: '', '' -->
                            <ul class="footer-list" style="">
                              <li v-for="contact in address" v-bind:key="contact.id"><strong>Address :</strong> {{ contact.address }}</li><br/>
                              <li v-for="phone in phone_number" v-bind:key="phone.id"><strong>Call or SMS</strong> <strong>:</strong> {{ phone.phone_number }}</li><br/>
                             
                              <li v-for="whatsapp in whatsapp_number" v-bind:key="whatsapp.id"><strong>Whatsapp Us</strong> <strong>:</strong> {{ whatsapp.whatsapp_number }}</li><br/>

                              <span  v-if="telegram_number">
                                <li v-for="telegram in telegram_number" v-bind:key="telegram.id"><strong>Telegram</strong> <strong>:</strong> {{ telegram.telegram_number }}</li><br/>
                              </span>

                              <li  v-for="email in email_address" v-bind:key="email.id"><strong>Email</strong> : {{ email.email_address }}</li><br/>
                              <li><strong>Monday - Friday :</strong> 9:00 am - 10:00 pm <br> <strong>Saturday - Sunday :</strong> Closed</li>
                            </ul>
                        </div><!-- /.col-md-3 -->

                        <div class="col-md-4 widget">
                            <p><img src="assets/img/app-card.png" alt="app card" style="float: left;" width="90%" height="NaN"></p>
                        <hr>
                        <div  v-for="whatsapp in whatsapp_number" v-bind:key="whatsapp.id">
                          <a :href="`https://api.whatsapp.com/send?phone=` +  whatsapp.whatsapp_number  + `&text=How can we help you?`" class="float" target="_blank">
                          <i class="fa fa-whatsapp my-float"></i>
                          </a>
                        </div>
                        </div><!-- /.col-md-3 -->
                    </div><!-- /.row -->
                </div><!-- /.container -->
            </div><!-- /.primary-footer -->

            <div class="secondary-footer brand-bg darken-2">
                <div class="container">
                    <span class="copy-text">Copyright &copy; 2020 <a href="#">VesTrade</a> &nbsp;  | &nbsp;  All Rights Reserved.</span>
                </div><!-- /.container -->
            </div><!-- /.secondary-footer -->
        </footer>


        <!-- Preloader -->
        <div id="preloader">
          <div class="preloader-position"> 
            <img src="assets/img/logo-colored.png" alt="logo" >
            <div class="progress">
              <div class="indeterminate"></div>
            </div>
          </div>
        </div>
        <!-- End Preloader --> 
  </div>
</template>

<script>
import axios from "axios";
import API_URL  from '../../common/config'

export default {
    name: 'Footer',
    data () {
    return {
      phone_number: '',
      whatsapp_number: '',
      address: '',
      email_address: '',
      telegram_number: null,
    }
  },
  created () {
    this.addressDetails()
  },
  methods: {
    addressDetails () {
      axios.get(API_URL + 'pages/phone_number/')
        .then(res => {
          this.phone_number = res.data
          // console.log('== this.phone_number ', this.phone_number)
        })
        axios.get(API_URL + 'pages/whatsapp_number/')
        .then(res => {
          this.whatsapp_number = res.data
          // console.log('== this.whatsapp_number ', this.whatsapp_number)
        })
        axios.get(API_URL + 'pages/telegram_number/')
        .then(res => {
          if(res.data.length > 0){
            this.telegram_number = res.data
            //console.log('== this.telegram_number ', this.telegram_number, res.data.length)
          }
        })
        axios.get(API_URL + 'pages/address/')
        .then(res => {
          this.address = res.data
          // console.log('== this.address ', this.address)
        })
        axios.get(API_URL + 'pages/email_address/')
        .then(res => {
          this.email_address = res.data
          // console.log('== this.email_address ', this.email_address)
        })
    }
    }
}
</script>

<style >
.footer-list li{
  color:#fff;
}
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
</style>