<template>
  <div id="user-dashboard">

    <div class="row">
      <div class="col-md-12">
        <h1>Trader Dashboard</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div style="height:62px; background-color: #FFFFFF; overflow:hidden; box-sizing: border-box; border: 1px solid #56667F; border-radius: 4px; text-align: right; line-height:14px; block-size:62px; font-size: 12px; font-feature-settings: normal; text-size-adjust: 100%; box-shadow: inset 0 -20px 0 0 #56667F;padding:1px;padding: 0px; margin: 0px; width: 100%;"><div style="height:40px; padding:0px; margin:0px; width: 100%;"><iframe src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=light&pref_coin_id=1505&invert_hover=" width="100%" height="36px" scrolling="auto" marginwidth="0" marginheight="0" frameborder="0" border="0" style="border:0;margin:0;padding:0;"></iframe></div><div style="color: #FFFFFF; line-height: 14px; font-weight: 400; font-size: 11px; box-sizing: border-box; padding: 2px 6px; width: 100%; font-family: Verdana, Tahoma, Arial, sans-serif;"><a href="https://coinlib.io" target="_blank" style="font-weight: 500; color: #FFFFFF; text-decoration:none; font-size:11px">Cryptocurrency Prices</a>&nbsp;by Coinlib</div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
    <div class="row">
      <div class="col-md-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xs-12 col-md-6">
                <div id="chart" class="date_chart_top">
                  <apexcharts class="date_chart" width="100%" :height="240" type="area" :options="chartOptions1.chartOptions" :series="chartOptions1.series"></apexcharts>
                </div>
              </div>
              <div class="col-xs-12 col-md-6">
                <h4 class="page-title"><b>Wallet Balance Chart</b></h4>
                <div  v-show="is_pie_null" class="pie-chart-left">
                  <img src="./../assets/img/demo-pie-chart.png" style="margin-left: 10px;" />
                </div>
                <div v-show="!is_pie_null" id="chart" ref="chart" class="pie-chart-left">
                  <apexcharts type="donut" :width="360" :height="360" :options="chartOptions2" :series="chartOptions2.series"></apexcharts>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
        <br> <hr> <br>
      <div class="col-md-4">
        <div class="card card-chart">
          <div class="">
            <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
              <h4 class="page-title"><b>Cryptocurrency Trade</b></h4>
            </div>
          </div>                
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">arrow_forward</i>
              <router-link to="/user/user-crypto-trading"><b> Click here to start</b></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-chart">
          <div class="">
            <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
              <h4 class="page-title"><b>Forex Trade</b></h4>
            </div>
          </div>              
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">arrow_forward</i>
              <router-link to="/user/user-forex-trading"><b> Click here to start</b></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-chart">
          <div class="">
            <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
              <h4 class="page-title"><b>Binary Trade</b></h4>
            </div>
          </div>  
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">arrow_forward</i>
              <router-link to="/user/user-binary-trading"><b> Click here to start</b></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="card card-chart">
          <div class="">
            <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
              <h4 class="page-title"><b>Deposit Funds</b></h4>
            </div>
          </div>                
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">arrow_forward</i>
              <router-link to="/user/user-deposit-funds"><b> Click here to start</b></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-chart">
          <div class="">
            <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
              <h4 class="page-title"><b>Withdraw Funds</b></h4>
            </div>
          </div>              
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">arrow_forward</i>
              <router-link to="/user/user-withdraw-funds"><b> Click here to start</b></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-chart">
          <div class="">
            <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
              <h4 class="page-title"><b>Wallet Balance</b></h4>
            </div>
          </div>              
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">arrow_forward</i>
              <router-link to="/user/userwallet-balance"><b> Click here to start</b></router-link>
            </div>
          </div>
        </div>
      </div>       
    </div>

  <div class="row">
      <div class="col-md-4">
        <div class="card card-chart">
          <div class="">
            <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
              <h4 class="page-title"><b>Personal Profile</b></h4>
            </div>
          </div>                
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">arrow_forward</i>
              <router-link to="/user/user-personal-profile"><b> Click here to start</b></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-chart">
          <div class="">
            <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
              <h4 class="page-title"><b>Upload Documents</b></h4>
            </div>
          </div>              
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">arrow_forward</i>
              <router-link to="/user/user-upload-documents"><b> Click here to start</b></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-chart">
          <div class="">
            <div class="ct-chart" style="padding:20px !important; margin-bottom: -15px !important;">
              <h4 class="page-title"><b>Refer a Friend</b></h4>
            </div>
          </div>  
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">arrow_forward</i>
              <router-link to="/user/user-refer-a-friend"><b> Click here to start</b></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- <BackendScripts /> -->
 
  </div>
</template>

<script>
/* eslint-disable */
// import Vue from 'vue'
// import VueApexCharts from 'vue-apexcharts'
// Vue.use(VueApexCharts);
//import $ from 'jquery';
import axios from "axios";
import API_URL  from './../common/config'
// import BackendScripts from '../components/Partials/backend-scripts'
import {store} from '../store'
export default {
    name: 'UserDashboard',
    components: {
      // BackendScripts,
      //apexcharts: VueApexCharts,
    },
    data() {
      return {
        walletBalance: {
          balance: '', 
          withdraw: '', 
          amount: '', 
          profit: '', 
          trading_type:'',
        },
        series2: [2, 4, 5, 6],
        labels2: ['a', 'b', 'c', 'd'],
        is_pie_null: true
      }
    },
    computed: {
      user () {
        console.log('is_admin =', store.getters.is_admin)
        return store.getters.is_admin
      },
      chartOptions1(){
        return {
          chartOptions: {
            chart: {
              id: 'vuechart-example'
            },
            fill: {
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              }
            },
            xaxis: {
              categories: ['June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
            }
          },
          series: [{
            name: 'Days',
            data: [30, 31, 31, 30, 31, 30, 31]
          }]
        }
      },

    // colors: ['#E60B13', '#1F1E1E', '#6D6D6D', '#CECECE', 'rgba(255,87,93,.77)'],
      chartOptions2: function() {
      return {
      colors: [
        "cornflowerblue",
        "olivedrab",
        "orange",
        "tomato",
        "crimson",
        "purple",
        "turquoise",
        "forestgreen",
        "navy",
      ],
      legend: {
      fontSize: '14px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 400,
      itemMargin: {
        vertical: 10
      },
      formatter: function(seriesName, opts) {
        return '<div style="margin-left:-10px; style="margin-right:-50px; margin-top: 0px; width:100% !important;">' + '<span style="width:150px !important;">' + seriesName + '</span>' + '<br><span style="margin-left: 5px;">' + opts.w.globals.series[opts.seriesIndex] + '</span>' + '</div>'
      }
    },
    dataLabels: {
      enabled: false,
    },
    labels: [' Deposit', ' Profit', 'Withdraw', 'Balance'],
     plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Rubik',
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                // total: {
                //   show: true,
                //   label: 'Balance',
                //   color: '#373d3f',
                //   formatter: function (w) {
                //     //const bal = this.walletBalance.balance
                //     return this.walletBalance.balance
                //     // return  w.globals.seriesTotals.reduce((a, b) => {
                //     //  return  a + b
                //     // }, 0)
                //   }
                // }
              }
            }
          }
        },
      /*   : {
          balance: '', this.walletBalance.balance
          withdraw: '', this.walletBalance.withdraw
          amount: '', this.walletBalance.amount
          profit: '', this.walletBalance.profit
          trading_type:'',this.walletBalance.trading_type */
          //series: [56, 45, 543, 54]

          series: [this.walletBalance.amount, this.walletBalance.profit, this.walletBalance.withdraw, this.walletBalance.balance]
        }
      },
    },
    mounted() {
        console.log('tag', store.getters.is_admin)
        //this.createPie(".pieID.legend", ".pieID.pie");
        //this.createPieChart(".pieID.legend", ".pieID.pie");
      },
    created(){
      console.log('tag', this.$store.getters.is_admin)
      this.WalletBalanceFunc()
      this.isPieNull()

      // this.walletBalance = {
      //     balance: '', 
      //     withdraw: '', 
      //     amount: '', 
      //     profit: '', 
      //     trading_type:'',
      //   }
    },
    methods: {
      isPieNull (){
        if(this.walletBalance.amount > 0 || this.walletBalance.withdraw > 0 ){
        this.is_pie_null = false
      }},
      WalletBalanceFunc() {
        var token = JSON.parse(localStorage.getItem('userData')).token
          var headers = {'Authorization': `Token ${token}`}

          axios.get(API_URL + 'deposit-withdraw/dashbaord-withdrawal/', {headers:headers})
          .then(res => {
            console.log('walletBalance 1= : ', res)
            this.walletBalance.balance = res.data.balance
            this.walletBalance.withdraw = res.data.withdraw
            this.walletBalance.amount = res.data.amount
            this.walletBalance.profit = res.data.profit
            this.walletBalance.trading_type = res.data.trading_type

            this.isPieNull()

        })
      },


    //   sliceSize(dataNum, dataTotal) {
    //   return (dataNum / dataTotal) * 360;
    // },
    // addSlice(sliceSize, offset, sliceID, color) {
    //   const pieElement = this.$refs.pieChart;
    //   pieElement.innerHTML += `<div class='slice ${sliceID}'><span></span></div>`;
    //   offset = offset - 1;
    //   const sizeRotation = -179 + sliceSize;
    //   const sliceSelector = `.${sliceID}`;
    //   const sliceSpanSelector = `.${sliceID} span`;
    //   const slice = pieElement.querySelector(sliceSelector);
    //   const sliceSpan = pieElement.querySelector(sliceSpanSelector);
    //   slice.style.transform = `rotate(${offset}deg) translate3d(0,0,0)`;
    //   sliceSpan.style.transform = `rotate(${sizeRotation}deg) translate3d(0,0,0)`;
    //   sliceSpan.style.backgroundColor = color;
    // },
    // iterateSlices(sliceSize, offset, dataCount, sliceCount, color) {
    //   const sliceID = `s${dataCount}-${sliceCount}`;
    //   const maxSize = 179;
    //   if (sliceSize <= maxSize) {
    //     this.addSlice(sliceSize, offset, sliceID, color);
    //   } else {
    //     this.addSlice(maxSize, offset, sliceID, color);
    //     this.iterateSlices(
    //       sliceSize - maxSize,
    //       offset + maxSize,
    //       dataCount,
    //       sliceCount + 1,
    //       color
    //     );
    //   }
    // },
    // createPieChart() {
    //   const dataElement = ".pieID.legend";
    //   const listData = [];
    //   this.$el.querySelectorAll(`${dataElement} span`).forEach((span) => {
    //     listData.push(Number(span.innerHTML));
    //   });
    //   const listTotal = listData.reduce((acc, val) => acc + val, 0);
    //   let offset = 0;
    //   const color = [
    //     "cornflowerblue",
    //     "olivedrab",
    //     "orange",
    //     "tomato",
    //     "crimson",
    //     "purple",
    //     "turquoise",
    //     "forestgreen",
    //     "navy",
    //     "gray"
    //   ];
    //   listData.forEach((data, i) => {
    //     const size = this.sliceSize(data, listTotal);
    //     this.iterateSlices(size, offset, i, color[i]); offset += size; }); }, 
        

}
}

</script>

<style scoped>

.date_chart_top {
  position: relative;
}
.date_chart_top .date_chart {
  position: relative;
  z-index: 0;
}

@keyframes bake-pie {
  from {
    transform: rotate(0deg) translate3d(0,0,0);
  }
}
.pie-chart-left {
  /* Set the default margin-left value */
  margin-left: -15px;
  margin-top: 25px;
}
@media screen and (max-width: 767px) {
  /* Apply styles when the screen width is 767px or less (mobile devices) */
  .pie-chart-left {
    margin-left: -30px;
    margin-top: 25px;
  }
}
.legend-info {
  margin-left: 15px;
}
/* .pieID {
  display: inline-block;
  vertical-align: top;
}
.pie {
  height: 200px;
  width: 200px;
  position: relative;
  margin: 0 30px 30px 0;
}
.pie::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 100px;
  background: #EEE;
  border-radius: 50%;
  top: 50px;
  left: 50px;
}
.pie::after {
  content: "";
  display: block;
  width: 120px;
  height: 2px;
  background: rgba(0,0,0,0.1);
  border-radius: 50%;
  box-shadow: 0 0 3px 4px rgba(0,0,0,0.1);
  margin: 220px auto;
  
}
.slice {
  position: absolute;
  width: 200px;
  height: 200px;
  clip: rect(0px, 200px, 200px, 100px);
  animation: bake-pie 1s;
}
.slice span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  clip: rect(0px, 200px, 200px, 100px);
}
.legend {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 50;
  background: #FFF;
  padding: 15px;
  font-size: 13px;
  box-shadow: 1px 1px 0 #DDD,
              2px 2px 0 #BBB;
}
.legend li {
  width: 280px;
  height: 1.25em;
  margin-top: 5px;
  margin-bottom: 0.7em;
  padding-left: 0.5em;
  border-left: 1.25em solid black;
}
.legend em {
  font-style: normal;
}
.legend span {
  float: right;
} */
</style>