<template>
  <div id="deposit-page">
    <!--page title start-->
        <section class="page-title page-title-center cover-4 padding-top-110 padding-bottom-60 overlay purple-5 fixed-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="white-text font-20 text-bold" style="margin-top:45px;">Deposit</h4>
                    </div>
                </div>
            </div>
        </section>
       <!-- blog section start -->
        <section class="blog-section section-padding">
            <div class="container">
                <h1>Deposit</h1>
                <div class="row">
                    <div class="col-md-12">
                      <div class="posts-content default-blog">

                        <article class="post-wrapper">
                          <div class="entry-content">
                           
                           <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                    <div class="card card-chart" v-for="item in depositInstructions" v-bind:key="item.id">
                                        <div class="card-body">
                                          <p><span v-html="item.instruction_details"></span></p>
                                          <p>&nbsp;</p>
                                        </div>
                                    </div>
                                    <br>
                                  </div><!-- End Column    -->
                                  
                                  <div class="col-lg-6 col-md-6 col-sm-6" v-for="bank in bankDeails" v-bind:key="bank.id">
                                   
                                    <div class="card card-chart">
                                      <p align="center" style="margin-bottom: -10px !important; color: red;"><b>Click Below for Deposit Details</b></p>
                                      <div class="" style="margin-top:15px !important; padding:5px !important; margin-bottom:15px !important;">
                                        <h5 class="page-title" align="center" @click="selectItem(bank.id)"><a>{{ bank.title }}</a></h5>
                                      </div>
                                      <div class="card-body" style="display: none !important;" v-show="isOpen" v-if="bank.id === activeItem">
                                        <p><span v-html="bank.bank_details"></span></p>
                                        <p v-if="bank.file"><img :src="bank.file" width="100%"></p>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- End Column    -->
                           </div>
                           <!-- end row -->
                          </div><!-- .entry-content -->

                        </article><!-- /.post-wrapper -->


                        
                      </div><!-- /.posts-content -->
                    </div><!-- /.col-md-8 -->
                  </div><!-- /.row -->
            </div><!-- /.container -->
        </section>
        <!-- blog section end -->
     
  </div>
</template>

<script>
import axios from "axios";
import API_URL  from './../common/config'

export default {
    name: 'Deposit',
    data() {
      return {
        activeItem: null,
        isOpen: false,
        depositInstructions: [],
        bankDeails: [],
      }
    },
    created() {
      this.getDepositInstruction()
      this.getBankDetails()
    },
    methods: {
      getDepositInstruction() {
        axios.get(API_URL + 'deposit-withdraw/deposit-instructions/')
        .then(res => {
          this.depositInstructions = res.data
        })
      },
      getBankDetails() {
        axios.get(API_URL + 'deposit-withdraw/bank-details/')
        .then(res => {
          //console.log('this.bankDeails = ', res.data)
          this.bankDeails = res.data
        })
      },
      selectItem(i) {
        this.activeItem = i;
        this.isOpen = !this.isOpen
        console.log('this.activeItem = ', this.activeItem)
        console.log('this.isOpen = ', this.isOpen)
      },
    },
}
</script>

<style scoped>
.activeItem {
  display: block;
}
p {
  font-size: 14px;
}
</style>

