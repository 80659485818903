<template>
  <div id="guide-page">
    <!--page title start-->
        <section  id="guide-page" class="page-title page-title-center cover-4 padding-top-110 padding-bottom-60 overlay purple-5 fixed-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h4 class="white-text font-20 text-bold" style="margin-top:45px;">Guide</h4>
                    </div>
                </div>
            </div>
        </section>
       <!-- blog section start -->
        <section class="blog-section section-padding">
            <div class="container">
                <h1>Guide</h1>
                <div class="row">
                    <div class="col-md-12">
                      <div class="posts-content default-blog">

                        <article class="post-wrapper">
                          <div class="entry-content">
                            <blockquote>
                              <p class="entry-title"><router-link :to="'/crypto-trading'">Cryptocurrency Trading</router-link></p>
                            </blockquote>
                            <h3>How To Trade Cryptocurrency</h3>                            
                            <p><b>Scared of missing out? Here is a quick plan to get you trading cryptocurrency quickly;</b>                          
                            <br style="margin-bottom: 15px;">
                            <ol>
                            <li>Decide if you want to own the cryptocurrency, or simply have a hunch that it&rsquo;s value will go up or down.<ul>
                                <li>If you want to speculate on the price, you need a broker. Click here to <router-link :to="'/login'">Signup with us.</router-link></li>
                                </ul>
                            </li>
                            <li>Fund your account</li>
                            <li>Buy the Cryptocurrency you want, or open a trade on it&rsquo;s price.</li>
                            </ol>
                            </p>  
                            <p>Congratulations, you are now a cryptocurrency trader!
                                <br>
                               Trading crypto generally revolves around speculating on it&rsquo;s price,   rather than owning any of the actual coins.
                            </p>          
                          
                            <p class="btn">
                            <span class="posted-in">
                               <router-link :to="'/crypto-trading'" style="color:#fff;"><b>Learn more...</b></router-link>
                            </span>
                            </p>
                          </div><!-- .entry-content -->

                        </article><!-- /.post-wrapper -->


                        <article class="post-wrapper format-video">

                          <div class="entry-content">
                            <blockquote>
                              <p class="entry-title"><router-link :to="'/forex-trading'">Forex Trading</router-link></p>
                            </blockquote>
                            <h3>Why Trade Forex?</h3>
                            <p>The forex currency market offers the day trader the ability to speculate on movements in foreign exchange markets and particular economies or regions. Furthermore, with no central market, forex offers trading opportunities around the clock.
                            <ul>
                            <li><strong>Liquidity</strong> &ndash; In the 2020 forex market, the average   volume traded per day is $6.6 trillion. So, there is an abundance of   trades and moves you can make.</li>
                            <li><strong>Diversity</strong> &ndash; Firstly, you have the pairs stemming   from the eight major global currencies. On top of that, many regional   currency pairings are also available for trade. More options, more   opportunities to turn a profit.</li>
                            <li><strong>Accessibility</strong> &ndash; While not quite 24/7, the forex   market is readily accessible, open twenty-four hours a day, five days a   week. As a result, you decide when to trade and how to trade.</li>
                            <li><strong>Leverage</strong> &ndash; A significant amount of forex currency   pairings are traded on margin. This is because leverage can be used to   help you both buy and sell large quantities of currency. The greater the   quantity, the greater the potential profit &ndash; or loss.</li>
                            <li><strong>Low commissions</strong> &ndash; Forex offer relatively low costs   and fees compared to other markets. In fact, some firms don&rsquo;t charge any   commission at all, you pay just the bid/ask spreads. True ECN firms may   also offer 0 spread!</li>
                            </ul>
                            </p>
                            <p class="btn">
                            <span class="posted-in">
                                <router-link :to="'/forex-trading'" style="color:#fff;"><b>Learn more...</b></router-link>
                            </span>
                            </p>
                          </div><!-- .entry-content -->

                        </article><!-- /.post-wrapper -->


                        <article class="post-wrapper format-video">

                          <div class="entry-content">
                            <blockquote>
                              <p class="entry-title"><router-link :to="'/binary-trading'">Binary Option Trading</router-link></p>
                            </blockquote>
                            <h3>A Brief History</h3>
                            <p>If you want to start trading binary options full-time, a detailed   understanding of their origins will help. Binary or &lsquo;digital&rsquo; options   have been around for decades. Originally though, it was only large   institutions and the fabulously wealthy that had access. However, 2008   saw the US Securities and Exchange Commission open the floodgates by   allowing binary options to be traded through an exchange.
                            <br>
                            The internet and technology have since given the whole world access   to these &lsquo;digital&rsquo; options. As it stands, with low barriers to entry for   savvy day traders and a simple to understand preposition, the demand   for these digital trades will only increase.</p>
                            <h3>Option Types</h3>
                            <p>There are a number of different option types to choose from. The most popular types are listed in the brief glossary below.
                            <ul>
                            <li><strong>Up/Down (High/Low)</strong> &ndash; The most simple and prevalent   binary option. Will the price be higher or lower than the current price   when the expiry time comes?</li>
                            <li><strong>In/Out (Range or Boundary)</strong> &ndash; A &lsquo;high&rsquo; and &lsquo;low&rsquo;   figure will be set. You are then making a determination as to whether   the price will finish within or outside of these boundaries.</li>
                            <li><strong>Touch/No Touch</strong> &ndash; Levels will be set that are either   higher or lower than the current price. You then enter a position as to   whether the price will &lsquo;touch&rsquo; these levels between the time of trade   and expiry. Payout will come as soon as the touch takes place.</li>
                            <li><strong>Ladder</strong> &ndash; These are similar to up/down trades.   However, instead of using the current price, the ladder will have   pre-determined levels that are staggered up or down. These normally   demand a substantial price move. The flip side of this is returns will   frequently exceed 100%. Although it is worth noting, both sides of a   trade are not always available.</li>
                            </ul>
                            </p>
                            <p class="btn">
                            <span class="posted-in">
                                <router-link :to="'/binary-trading'" style="color:#fff;"><b>Learn more...</b></router-link>
                            </span>
                            </p>
                          </div><!-- .entry-content -->

                        </article><!-- /.post-wrapper -->
                       

                      </div><!-- /.posts-content -->
                    </div><!-- /.col-md-8 -->
                  </div><!-- /.row -->
            </div><!-- /.container -->
        </section>
        <!-- blog section end -->
     
  </div>
</template>

<script>
export default {
    name: 'GuidePage'
}
</script>

<style scoped>

</style>