<template>
  <div id="edit-my-profile">
    <div class="row">
      <h1>Edit My Profile</h1>
  </div>
  <!-- <BackendScripts /> -->
  </div>
</template>

<script>
// import BackendScripts from '../components/Partials/backend-scripts'

export default {
    name: 'EditMyProfile',
    components: {
      // BackendScripts
    }
}
</script>

<style>

</style>